import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Image,
  Segment,
  Checkbox,
  Divider,
  Message,
} from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _auth from "../actions/authentication";
import Notifier from "../common/Notifier";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      password: "",
      confirmpassword: "",
      passwordUnmatched: false,
      signedUA: false,
      validationFailed: false,
      redirect: false,
    };
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { fname, lname, email, password, confirmpassword } = this.state;
    if (
      password !== confirmpassword ||
      fname.trim().length === 0 ||
      lname.trim().length === 0 ||
      email.trim().length === 0 ||
      email.trim().indexOf("@") === -1 ||
      email.trim().indexOf("@") === 0
    ) {
      this.setState({ validationFailed: true });
    } else {
      this.props.actions.signup(fname, lname, email, password);
    }
  };

  render() {
    const { signedUA, validationFailed, redirect } = this.state;
    const { error, error_message, signupSuccess, signupRequest } = this.props;
    if (redirect) {
      return <Redirect to="/" />;
    }
    if (signupSuccess) {
      return (
        <Notifier
          size="small"
          type="info"
          title="Welcome to Medicine Buddha"
          content={<div>Your account has been successfully created.</div>}
          onOK={() => {
            this.setState({ redirect: true });
          }}
        />
      );
    }

    if (error) {
      return (
        <Notifier
          size="small"
          type="warn"
          title="Error while creating an account."
          content={<div>{error_message}</div>}
          onOK={() => {
            this.setState({ redirect: true });
          }}
        />
      );
    }

    if (validationFailed) {
      return (
        <Notifier
          size="small"
          type="warn"
          title="Validation Error"
          content={
            <div>One or more of the information provided are invalid.</div>
          }
          onOK={() => {
            this.setState({ validationFailed: false });
          }}
        />
      );
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 400 }}>
          <Image src="/assets/LogoBig.png" size="small" centered />
          <Divider hidden />
          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                name="fname"
                placeholder="First Name"
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                name="lname"
                placeholder="Last Name"
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                name="email"
                placeholder="E-mail address"
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                name="password"
                placeholder="Password"
                type="password"
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                name="confirmpassword"
                placeholder="Confirm Password"
                type="password"
                onChange={this.handleChange}
              />
              <Checkbox
                label="I have read and understood the"
                name="signedUA"
                checked={signedUA}
                onChange={(e) => this.setState({ signedUA: !signedUA })}
              />
              <Link to="/agreement"> Agreement document</Link>
              <Divider hidden />
              <Button
                color="blue"
                fluid
                loading={signupRequest}
                size="large"
                onClick={this.handleSubmit}
                disabled={!signedUA}
              >
                Sign up
              </Button>
            </Segment>
          </Form>
          <Message>
            Already have an account? <Link to="/login">Login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  (state) => {
    return {
      error: state.user.error,
      error_message: state.user.error_message,
      signupSuccess: state.user.signupSuccess,
      signupRequest: state.user.signupRequest,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(_auth, dispatch),
  })
)(Signup);
