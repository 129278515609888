import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Image,
  Message,
  Segment,
  Divider,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as authActions from "../actions/authentication";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      usertype: "patient",
    };
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { email, password, usertype } = this.state;
    this.props.actions.login(email, password, usertype);
  };

  render() {
    const { isLoggingIn, error, error_message } = this.props;
    const { email, password, usertype } = this.state;
    const loginDisabled =
      email.length === 0 || password.length === 0 || usertype.length === 0;
    const usertypeOption = [
      {
        key: "patient",
        text: "Patient",
        value: "patient",
      },
      {
        key: "doctor",
        text: "Practitioner",
        value: "doctor",
      },
    ];
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 400 }}>
          <Image src="/assets/LogoBig.png" size="medium" centered />
          <Divider hidden />
          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                name="email"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                icon="lock"
                name="password"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={this.handleChange}
              />
              <Form.Dropdown
                placeholder="Login as ..."
                options={usertypeOption}
                fluid
                selection
                onChange={(e, { value }) => this.setState({ usertype: value })}
              />
              <Button
                color="blue"
                fluid
                size="large"
                loading={isLoggingIn}
                onClick={this.handleSubmit}
                disabled={loginDisabled}
              >
                Login
              </Button>
            </Segment>
          </Form>
          {error && (
            <Message error floating>
              <Message.Header>Failed at signing you in.</Message.Header>
              <p>{error_message}</p>
            </Message>
          )}
          <Message>
            New to us? <Link to="/signup">Sign Up</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  (state) => ({
    isLoggingIn: state.user.authenticationInProgress,
    error: state.user.error,
    error_message: state.user.error_message,
  }),
  (dispatch) => ({
    actions: bindActionCreators(authActions, dispatch),
  })
)(Login);
