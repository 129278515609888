import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Agora from "./Agora";
import * as _consultationAppointment from "../actions/consultation";
import * as _doctorActions from "../actions/doctor";
import { Redirect } from "react-router-dom";
import Feedback from "./Feedback";

class VideoConference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaveCall: false,
      showFeedback: false,
      dontRenderAgora: false,
    };
  }
  triggerEndCall = () => {
    this.setState({ leaveCall: true });
  };
  callEnded = (duration) => {
    const { user, actions } = this.props;
    let consultationId = null;
    if (user.user_type === "patient") {
      consultationId = this.props.consultation.consultationId;
    } else {
      consultationId = this.props.doctor.sessionRequestors[0].consultation_id;
    }
    actions.endConsultation(consultationId, duration === null ? 0 : duration);
    this.setState({ showFeedback: true, dontRenderAgora: true });
  };
  render() {
    const { user, doctor_session, patient_session, consultation } = this.props;
    const { leaveCall, showFeedback, dontRenderAgora } = this.state;
    const { user_type } = user;

    const channel = user_type === "patient" ? patient_session : doctor_session;
    if (!channel) {
      if (user_type === "patient") {
        return <Redirect to="/feedback" />;
      } else {
        return <Redirect to="/" />;
      }
    }

    if (dontRenderAgora && user_type === "doctor") {
      setTimeout(() => {
        // console.log("called reload");
        window.location.reload();
      }, 0);
      return <Redirect to="/" />;
    }

    const doctor_type = user.authSession.hasOwnProperty("doctor_type")
      ? user.authSession.doctor_type
      : consultation.hasOwnProperty("selectedDoctor")
      ? consultation.selectedDoctor.doctor_code
      : "TIB_DOC";
    if (showFeedback) {
      if (user_type === "patient") {
        return (
          <Feedback
            consultationId={consultation.consultationId}
            status={consultation.feedbackStatus}
            sendFeedback={this.props.actions.sendFeedback}
          />
        );
      }
    } else if (!showFeedback) {
      return (
        <Agora
          channel={channel}
          user_type={user_type}
          doctor_type={doctor_type}
          callEnded={this.callEnded}
          leaveCall={leaveCall}
          closeAgora={() => this.setState({ showFeedback: true })}
          resetDoctorSession={() => this.props.actions.resetDoctorSession()}
        />
      );
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 0);
      return <Redirect to="/" />;
    }
  }
}

export default connect(
  (state) => {
    return {
      user: state.user,
      doctor: state.doctor,
      consultation: state.consultation,
      doctor_session: state.doctor.sessionId,
      patient_session: state.consultation.rtc_session_id,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(
      Object.assign({}, _doctorActions, _consultationAppointment),
      dispatch
    ),
  })
)(VideoConference);
