export const BACKEND_API = "https://api.medicinebuddhawellbeing.co.uk/api";
// export const BACKEND_API = "http://localhost:8000/api";
export const S_KEY = "pk_live_zqcP9GQSJrowFoelUPH5sCIJ00GQLgkeSS";

export const APPID = "dac8b1c8468d4ee6b726d5dcc2974e85";
export const CURRENCY_SYMBOL = {
  inr: "₹",
  gbp: "£",
  eur: "€",
};

export const MEDICINE_CHARGE = {
  inr: {
    1: 500,
    2: 750,
    3: 1000,
  },
  gbp: {
    1: 30,
    2: 50,
    3: 75,
  },
  eur: {
    1: 35,
    2: 60,
    3: 90,
  },
};
