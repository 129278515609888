import * as _support from "../constants/support";

const initialState = {
  supportRequestSent: false,
  supportRequestStatus: "",
  supportRequestResponse: null,
  donationInProgress: false,
  donationStatus: null,
  donationFailedMsg: null,
};

export default function support(state = initialState, action = {}) {
  switch (action.type) {
    case _support.RAISING_TICKET: {
      return {
        ...state,
        supportRequestSent: true,
      };
    }
    case _support.TICKET_RAISED: {
      return {
        ...state,
        supportRequestSent: false,
        supportRequestStatus: "success",
        supportRequestResponse: action.data,
      };
    }
    case _support.TICKET_FAILED: {
      return {
        ...state,
        supportRequestSent: false,
        supportRequestStatus: "failed",
        supportRequestResponse: action.data,
      };
    }
    case _support.TICKET_ERRORED: {
      return {
        ...state,
        supportRequestSent: false,
        supportRequestStatus: "error",
        supportRequestResponse: null,
      };
    }
    case _support.TICKET_RESET: {
      return {
        ...state,
        supportRequestSent: false,
        supportRequestStatus: "",
        supportRequestResponse: null,
      };
    }

    case _support.DONATION_REQUEST: {
      return {
        ...state,
        donationInProgress: true,
      };
    }

    case _support.DONATION_SUCCESS: {
      return {
        ...state,
        donationInProgress: false,
        donationStatus: action.data,
      };
    }

    case _support.DONATION_RESET: {
      return {
        ...state,
        donationStatus: null,
      };
    }

    case _support.DONATION_FAILED: {
      return {
        ...state,
        donationInProgress: false,
        donationStatus: null,
        donationFailedMsg: action.data,
      };
    }

    default: {
      return state;
    }
  }
}
