import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";

import Notifier from "../common/Notifier";

const MenuComponent = ({ usertype }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [logout, setLogout] = useState(false);
  if (logout) {
    return (
      <Notifier
        title="Logout"
        size="small"
        content="Have you mark yourself as unavailable?"
        onOK={() => {
          localStorage.removeItem("MedBuddha");
          window.location = "/";
        }}
        onClose={() => setLogout(false)}
      />
    );
  }
  return (
    <Menu attached="top">
      <Menu.Item>
        <Image src="/assets/180.png" size="mini" style={{ marginRight: 6 }} />
      </Menu.Item>
      {usertype === "patient" && (
        <>
          <Menu.Item
            as={Link}
            to="/prescription"
            active={activeItem === "home"}
            onClick={() => setActiveItem("home")}
          >
            Prescription
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/tibetan-medicine"
            active={activeItem === "tibetan_doctor"}
            onClick={() => setActiveItem("tibetan_doctor")}
          >
            Tibetan Medicine Doctor
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/wellness"
            active={activeItem === "wellness"}
            onClick={() => setActiveItem("wellness")}
          >
            Wellness Practitioner
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/pay-forward"
            active={activeItem === "donation"}
            onClick={() => setActiveItem("donation")}
          >
            Pay Forward
          </Menu.Item>
        </>
      )}
      {usertype === "doctor" && (
        <Menu.Item
          as={Link}
          to="/"
          active={activeItem === "work"}
          onClick={() => setActiveItem("work")}
        >
          Consultation
        </Menu.Item>
      )}
      <Menu.Item
        as={Link}
        to="/support"
        active={activeItem === "support"}
        onClick={() => setActiveItem("support")}
      >
        Support
      </Menu.Item>
      <Menu.Item
        position="right"
        as={Link}
        to="#"
        active={activeItem === "logout"}
        onClick={() => {
          if (usertype === "doctor") {
            setLogout(true);
          } else {
            localStorage.removeItem("MedBuddha");
            window.location = "/";
          }
        }}
      >
        Log out
      </Menu.Item>
    </Menu>
  );
};

export default MenuComponent;
