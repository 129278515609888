import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { Container } from "semantic-ui-react";
import * as authActions from "./actions/authentication";

import Menu from "./common/Menu";
import Footer from "./common/Footer";
import Login from "./component/Login";
import Signup from "./component/Signup";
import Home from "./component/Home";
import TibetanMedicineAppointment from "./component/TibetanMedicine";
import PayForward from "./component/PayForward";
import Support from "./component/Support";
import Prescribe from "./component/Prescribe";
import Prescription from "./component/Prescription";
import WellnessPractitioner from "./component/WellnessPractitioner";
import Work from "./component/Work";
import StripeMinimal from "./component/StripeMinimal";
import VideoConference from "./component/VideoConference";
import Feedback from "./component/Feedback";
import Agreement from "./common/Agreement";

function sessionExists(actions) {
  let session = localStorage.getItem("MedBuddha");
  if (session) {
    actions.restoreSession(session);
  }
}

function Main({ user, actions }) {
  useEffect(() => {
    if (!user.authSession) {
      sessionExists(actions);
    }
  }, []);
  if (!user.authSession) {
    return (
      <Router>
        <Container>
          <div className="App">
            {/* <Route path="/">{!user.authSession && <Redirect to="/" />}</Route> */}
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/agreement">
              <Agreement />
            </Route>
          </div>
        </Container>
      </Router>
    );
  }

  const { user_type } = user;
  let mainContent = null;
  if (user_type === "doctor") {
    mainContent = (
      <Switch>
        <Route exact path="/">
          <Work />
        </Route>
        <Route exact path="/prescribe">
          <Prescribe />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/consultation">
          <VideoConference />
        </Route>
      </Switch>
    );
  } else {
    mainContent = (
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/prescription">
          <Prescription />
        </Route>
        <Route path="/tibetan-medicine">
          <TibetanMedicineAppointment />
        </Route>
        <Route path="/wellness">
          <WellnessPractitioner />
        </Route>
        <Route path="/pay-forward">
          <PayForward />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/payment">
          <StripeMinimal />
        </Route>
        <Route path="/consultation">
          <VideoConference />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
      </Switch>
    );
  }

  return (
    <Router>
      <Container>
        <div style={{ marginTop: "30px" }}>
          <Menu usertype={user.user_type} />
          {mainContent}
          <Footer />
        </div>
      </Container>
    </Router>
  );
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    actions: bindActionCreators(authActions, dispatch),
  })
)(Main);
