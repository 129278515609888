import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as docActions from "../actions/doctor";

import {
  Card,
  Feed,
  Button,
  Icon,
  Message,
  Form,
  Radio,
  Divider,
} from "semantic-ui-react";

class Work extends React.Component {
  requestFetchInterval = false;

  componentDidMount() {
    const { user, actions } = this.props;
    actions.getPastPatients(user.id);
  }

  render() {
    const {
      user,
      availability,
      pastPatients,
      // doctorProf,
      actions,
      requests,
      sessionId,
      prescription_consultationId,
    } = this.props;

    if (prescription_consultationId) {
      return <Redirect to="/prescribe" />;
    }

    const requestsReceived = requests.length !== 0;

    if (availability) {
      if (!this.requestFetchInterval) {
        this.requestFetchInterval = setInterval(function () {
          actions.checkAppointmentRequest(user.id);
        }, 2000);
      }
    } else {
      clearInterval(this.requestFetchInterval);
      this.requestFetchInterval = false;
    }

    if (sessionId) {
      if (this.requestFetchInterval) {
        clearInterval(this.requestFetchInterval);
        this.requestFetchInterval = false;
      }
      return <Redirect to="/consultation" />;
    }
    return (
      <div style={{ marginTop: 20 }}>
        <h1>Consultation Area</h1>
        <Divider />
        <Card.Group itemsPerRow={2}>
          <Card style={{ maxHeight: "80vh", overflowY: "scroll" }}>
            <Card.Group itemsPerRow={1}>
              <Card>
                <Card.Content>
                  <Card.Header>Activity</Card.Header>
                </Card.Content>
                <Card.Content>
                  <Form>
                    <Form.Field>
                      <Radio
                        label="Available"
                        toggle
                        checked={availability}
                        onChange={() => {
                          actions.changeAvailability(user.id, !availability);
                        }}
                      />
                    </Form.Field>
                  </Form>
                </Card.Content>
              </Card>
              {!requestsReceived && availability && (
                <Card style={{ boxShadow: "none" }}>
                  <Card.Content>
                    <Message icon floating>
                      <Icon name="circle notched" loading />
                      <Message.Content>
                        <Message.Header>
                          No Consultation Request received
                        </Message.Header>
                        We are checking for new consultation requests.
                      </Message.Content>
                    </Message>
                  </Card.Content>
                </Card>
              )}
              {requestsReceived && (
                <Card>
                  <Card.Content>
                    <Card.Header>Consultation Requests</Card.Header>
                  </Card.Content>
                  <Card.Content>
                    {requests.map((req) => (
                      <div key={req.consultation_id}>
                        <div className="content">
                          <img
                            alt="Requestor Profile"
                            src={req.image}
                            className="ui mini left floated image"
                          />
                          <span className="header">{req.name}</span>
                          <br />
                          <span className="meta">{req.reason}</span>
                          <button
                            className="ui green basic button"
                            style={{ float: "right" }}
                            onClick={(_) => {
                              this.props.actions.acceptConsultationRequest(
                                req.consultation_id,
                                user.id
                              );
                            }}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    ))}
                  </Card.Content>
                </Card>
              )}
            </Card.Group>
          </Card>
          <Card style={{ maxHeight: "80vh", overflowY: "scroll" }}>
            <Card.Content>
              <Card.Header>Past Patients</Card.Header>
            </Card.Content>
            <Card.Content>
              <Feed>
                {pastPatients.map((_p) => (
                  <Feed.Event key={_p.consultation_id}>
                    <Feed.Label image={_p.image} />
                    <Feed.Content>
                      <Feed.Date>{_p.date}</Feed.Date>
                      <Feed.Summary>
                        <Button
                          basic
                          fluid
                          animated="fade"
                          onClick={() => {
                            clearInterval(this.requestFetchInterval);
                            this.requestFetchInterval = false;
                            this.props.actions.getInventory(
                              _p.consultation_id,
                              _p.name
                            );
                          }}
                        >
                          <Button.Content hidden>
                            Prescribe Medicines
                          </Button.Content>
                          <Button.Content visible>{_p.name}</Button.Content>
                        </Button>
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                ))}
              </Feed>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      // doctorProf: state.doctor,
      pastPatients: state.doctor.pastPatients,
      availability: state.doctor.isAvailable,
      requests: state.doctor.sessionRequestors,
      sessionId: state.doctor.sessionId,
      patient: state.doctor.patient,
      prescription_consultationId: state.doctor.prescription_consultationId,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(docActions, dispatch),
  })
)(Work);
