import * as types from "../constants/doctor";

const initState = {
  isAvailable: false,
  fetching: false,
  error: null,
  inConsultation: false,
  patient: null,
  sessionId: null,
  pastPatients: [],
  sessionRequestors: [],
  inventory: [],
  prescriptions: [],
  prescription_consultationId: null,
  prescription_patient: null,
  prescription_status: null,
};

export default function doctor(state = initState, action = {}) {
  switch (action.type) {
    case types.SET_AVAILABILITY_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case types.SET_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        fetching: false,
        isAvailable: action.data,
      };
    }
    case types.SET_AVAILABILITY_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.data,
      };
    }

    case types.CHECK_CONSULTATION_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.SET_AVAILABILITY_VIA_LOGIN: {
      return {
        ...state,
        isAvailable: action.data,
      };
    }

    case types.INCOMING_CONSULTATION_REQ_SUCCESS: {
      const { patients, availability } = action.data;
      return {
        ...state,
        sessionRequestors: patients,
        isAvailable: availability,
      };
    }

    case types.INCOMING_CONSULTATION_REQ_FAIL: {
      return {
        ...state,
        error: action.data,
      };
    }

    case types.DOCTOR_CONSULTATION_STATUS_RESPONSE: {
      return {
        ...state,
        sessionId:
          action.data.rtc_session.length === 0 ? null : action.data.rtc_session,
        patient: action.data.patient,
      };
    }

    case types.PAST_PATIENTS_RESPONSE: {
      return {
        ...state,
        pastPatients: action.data,
      };
    }

    case types.PAST_PATIENTS_FAIL: {
      return {
        ...state,
        error: action.data,
      };
    }

    case types.ACCEPT_CONSULTATION_SUCCESS: {
      return {
        ...state,
        sessionId: action.data.rtc_session,
        inConsultation: true,
        patient: action.data.patient,
      };
    }

    case types.CONSULTATION_END_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: null,
        inConsultation: false,
        patient: null,
        sessionId: null,
        sessionRequestors: [],
      };
    }

    case types.ACCEPT_CONSULTATION_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.data,
        inConsultation: false,
        sessionId: null,
        patient: null,
      };
    }

    case types.ACCEPT_CONSULTATION_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.data,
        inConsultation: false,
        sessionId: null,
        patient: null,
      };
    }

    case types.FETCH_INVENTORY_REQUEST: {
      return {
        ...state,
        fetching: true,
        prescription_consultationId: action.data.id,
        prescription_name: action.data.name,
      };
    }
    case types.FETCH_INVENTORY_SUCCESS: {
      return {
        ...state,
        fetching: false,
        inventory: action.data,
      };
    }
    case types.FETCH_INVENTORY_FAILED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.SEND_PRESCRIPTION: {
      return {
        ...state,
        fetching: true,
      };
    }
    case types.SEND_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        fetching: false,
        prescription_status: action.data,
      };
    }
    case types.SEND_PRESCRIPTION_FAIL: {
      return {
        ...state,
        fetching: false,
        prescription_status: action.data,
      };
    }
    case types.SEND_PRESCRIPTION_ERROR: {
      return {
        ...state,
        fetching: false,
        prescription_status: action.data,
      };
    }

    case types.RESET_SESSION: {
      return {
        ...state,
        sessionId: null,
        patient: null,
        sessionRequestors: [],
      };
    }

    case types.RESET_PRESCRIPTION: {
      return {
        ...state,
        prescription_consultationId: null,
      };
    }

    default:
      return state;
  }
}
