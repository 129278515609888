import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Item,
  Icon,
  Input,
  Accordion,
  Message,
  Button,
  Modal,
  Divider,
  Dropdown,
} from "semantic-ui-react";

import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import * as _consultationAppointment from "../actions/consultation";
import { CURRENCY_SYMBOL, S_KEY } from "../constants/index";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const SplitForm = ({ onSuccess, onFail, secret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    // makes the payment directly to stripe
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        type: "card",
        card: elements.getElement(CardNumberElement),
      },
    });

    if (result.error) {
      console.log(result.error.message);
      onFail(result);
    } else {
      // payment processed
      if (result.paymentIntent.status === "succeeded") {
        onSuccess(result.paymentIntent);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card number
        <CardNumberElement options={options} />
      </label>
      <label>
        Expiration date
        <CardExpiryElement options={options} />
      </label>
      <label>
        CVC
        <CardCvcElement options={options} />
      </label>
      <Button
        content="Make payment & Book Appointment"
        labelPosition="right"
        icon="checkmark"
        positive
      />
    </form>
  );
};

const stripePromise = loadStripe(S_KEY);

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      paymentComplete: false,
      paymentDetails: null,
      voucher: "",
      reasonSelected: null,
      reasonForApi: null,
      donationAmount: "",
      invalidPayment: false,
    };
  }

  paymentFailed = () => {
    this.setState({ paymentComplete: false });
  };

  bookAppointment = (paymentDetails = null) => {
    const { voucher, reasonForApi } = this.state;
    const {
      user,
      doctor,
      actions,
      voucherApproved,
      currency,
      deduction,
    } = this.props;

    const { charges } = doctor;
    const tokenId = paymentDetails ? paymentDetails.id : null;
    const voucherApplied = voucherApproved ? voucher : "";
    let netTotal = charges[currency];
    const toReduce = (netTotal * deduction) / 100;
    if (voucherApproved) {
      netTotal = netTotal - toReduce;
      if (netTotal <= 1) {
        netTotal = 0;
      }
    }
    // and then follow up with the initiateConsultationRequest
    // payment is complete
    actions.initiateConsultationRequest(
      user.id,
      doctor.id,
      tokenId,
      voucherApplied,
      reasonForApi,
      paymentDetails
    );
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex, donationAmount } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    if (newIndex === 2) {
      // payment intent
      const { currency, doctor, deduction, voucherApproved } = this.props;
      const { charges } = doctor;
      let netTotal = charges[currency];
      const toReduce = (netTotal * deduction) / 100;
      if (voucherApproved) {
        netTotal = netTotal - toReduce;
        if (netTotal <= 1) {
          netTotal = 0;
        }
      }
      if (donationAmount != "" && parseInt(donationAmount) > 0) {
        netTotal += donationAmount;
      }
      if (netTotal !== 0) {
        // creates intent
        this.props.actions.getClientSecret(netTotal, currency);
        this.setState({ invalidPayment: false });
      } else {
        this.setState({
          invalidPayment: true,
          activeIndex: 0,
        });
      }
    }
    this.setState({ activeIndex: newIndex });
  };

  verifyVoucher = () => {
    const { voucher } = this.state;
    const { user, actions } = this.props;
    actions.verifyVoucher(voucher, user.id);
  };

  componentDidMount() {
    if (this.props.hasOwnProperty("currency")) {
      const { currency, doctor } = this.props;
      const { charges } = doctor;
      if (charges[currency] === 0) {
        const minimumDonation =
          currency === "inr" ? 100 : currency === "gbp" ? 1 : 1.5;
        this.setState({
          donationAmount: minimumDonation,
        });
      }
    }
  }

  render() {
    const {
      activeIndex,
      voucher,
      reasonSelected,
      donationAmount,
      invalidPayment,
    } = this.state;
    const {
      currency,
      doctor,
      onCancel,
      verifyingVoucher,
      voucherApproved,
      deduction,
      reasons,
      requestingConsultation,
      error,
      errorMessage,
      client_secret,
    } = this.props;

    const { name, gravatar, charges } = doctor;
    let netTotal = charges[currency];
    const toReduce = (netTotal * deduction) / 100;
    if (voucherApproved) {
      netTotal = netTotal - toReduce;
      if (netTotal <= 1) {
        netTotal = 0;
      }
    }
    netTotal += donationAmount;
    const charge = charges[currency];
    const donationMode = charge === 0;
    const noCharges = parseInt(netTotal) === 0;
    const amchiMessage =
      doctor.doctor_type === "TIB_DOC"
        ? "although diagnosis is usually quicker for more experienced doctor"
        : null;
    const callDuration = doctor.doctor_code === "TIB_DOC" ? 30 : 60;
    const chargeToShow =
      charges[currency] === 0
        ? `By Donation`
        : `${CURRENCY_SYMBOL[currency]} ${charges[currency]}`;
    const minimumDonation =
      currency === "inr" ? 100 : currency === "gbp" ? 1 : 1.5;
    // const bookBtnDisabled = netTotal === 0 ? false : !paymentComplete;

    if (isNaN(netTotal) || error) {
      // something died
      // notify the user and refresh
      const content = error
        ? errorMessage
        : `There was a failure whilst trying to fetch the details.`;
      return (
        <Modal open={true} closeOnDimmerClick={false} closeOnEscape={false}>
          <Modal.Content>
            <Message negative>
              <Message.Header>Failure</Message.Header>
              <p>{content}</p>
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => window.location.reload()}>
              Try again.
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    let modalTitle = "Consultation Payment";
    let mainContent = (
      <Item.Content verticalAlign="middle" style={{ width: "100%" }}>
        <Item.Header as="a">{name}</Item.Header>
        <Item.Meta>
          <div className="ui grid">
            <div className="five wide column">
              <span className="strong">Charges</span>
              {CURRENCY_SYMBOL[currency]} {charge}
            </div>
          </div>
        </Item.Meta>
        <Item.Description>
          <Divider />
          <Accordion>
            {!donationMode && (
              <>
                <Accordion.Title
                  active={true}
                  index={0}
                  onClick={this.handleClick}
                >
                  {/* <Icon name="dropdown" /> */}
                  Voucher or Gift code
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <p>
                    If you have a voucher or gift code, please enter below to
                    redeem it. You will be able to see the final charge before
                    booking. Thank you.
                  </p>
                  <div>
                    <Input
                      focus
                      action={{
                        color: "teal",
                        content: "redeem",
                        onClick: this.verifyVoucher,
                      }}
                      style={{ width: "100%" }}
                      placeholder="Enter Voucher/Coupon Code"
                      loading={verifyingVoucher}
                      size="large"
                      onChange={(e) => {
                        this.setState({
                          voucher: e.target.value.toUpperCase(),
                        });
                        if (voucherApproved) {
                          // any changes means this has to be reset
                          this.props.actions.resetVoucher();
                        }
                      }}
                      value={voucher}
                    />
                    {voucherApproved === false && (
                      <Message error header="Invalid Coupon code received." />
                    )}
                    {voucherApproved && (
                      <Message positive>
                        <Message.Header>Coupon code applied</Message.Header>
                        <Divider />
                        <div className="">
                          Deduction
                          <span
                            className="strong"
                            style={{ marginLeft: "20px" }}
                          >
                            - {CURRENCY_SYMBOL[currency]} {toReduce}
                          </span>
                          To Pay
                          <span
                            className="strong"
                            style={{ marginLeft: "20px" }}
                          >
                            {CURRENCY_SYMBOL[currency]} {netTotal}
                          </span>
                        </div>
                      </Message>
                    )}
                  </div>
                </Accordion.Content>
              </>
            )}
            {donationMode && (
              <>
                <Accordion.Title
                  active={true}
                  index={0}
                  onClick={this.handleClick}
                >
                  Donation
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <div>
                    <Input
                      focus
                      type="number"
                      style={{ width: "100%" }}
                      placeholder="Enter Donation amount"
                      size="large"
                      onChange={(e) => {
                        const parsed =
                          e.target.value !== ""
                            ? parseInt(e.target.value) < 0
                              ? ""
                              : parseInt(e.target.value)
                            : "";
                        this.setState({
                          donationAmount: parsed,
                        });
                      }}
                      value={donationAmount}
                    />
                  </div>
                  {(donationAmount === 0 || donationAmount === "") && (
                    <span>
                      Minimum donation amount is {CURRENCY_SYMBOL[currency]}{" "}
                      {minimumDonation}{" "}
                    </span>
                  )}
                </Accordion.Content>
              </>
            )}
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              Reason for Consultation
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Dropdown
                placeholder="Select your reason"
                fluid
                search
                selection
                options={reasons}
                onChange={(_, { value }) => {
                  if (value !== "other") {
                    this.setState({ reasonForApi: value });
                  }
                  this.setState({ reasonSelected: value });
                }}
              />
              {reasonSelected === "other" && (
                <Input
                  focus
                  style={{ width: "100%", marginTop: "8px" }}
                  placeholder="Enter reason"
                  onChange={(e) =>
                    this.setState({ reasonForApi: e.target.value })
                  }
                />
              )}
            </Accordion.Content>
            {!noCharges && (
              <>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Payment
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <Divider />
                  <Elements stripe={stripePromise}>
                    <SplitForm
                      onSuccess={this.bookAppointment}
                      onFail={this.paymentFailed}
                      secret={client_secret}
                    />
                  </Elements>
                </Accordion.Content>
              </>
            )}
          </Accordion>
          {invalidPayment && (
            <Message negative>
              <p>
                Invalid amount being charged. Please try again by either
                refreshing the page.
              </p>
            </Message>
          )}
        </Item.Description>
      </Item.Content>
    );

    if (requestingConsultation) {
      modalTitle = "Consultation";
      mainContent = (
        <Item.Content verticalAlign="middle" style={{ width: "100%" }}>
          <Item.Header as="a">{name}</Item.Header>
          <Item.Meta>
            <div className="ui grid">
              <div className="five wide column">
                <span className="strong">Charges</span>
                {chargeToShow}
              </div>
            </div>
          </Item.Meta>
          <Item.Description>
            <Message color="teal">
              {name} is accepting your request. Please do not close or refresh
              the page, it takes upto 30 seconds to connect. Your consultation
              can take up to {callDuration} minutes {amchiMessage}.
            </Message>
          </Item.Description>
        </Item.Content>
      );
    }

    return (
      <Modal
        onClose={onCancel}
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content image>
          <Item.Group>
            <Item>
              <div className="ui medium image">
                <img
                  src={gravatar}
                  // onLoad={() => console.log("loaded img")}
                  alt="Doctor Profile"
                />
              </div>
              {mainContent}
            </Item>
          </Item.Group>
        </Modal.Content>
        <Modal.Actions>
          {!requestingConsultation && (
            <Button
              color="black"
              onClick={() => {
                this.props.actions.resetVoucher();
                onCancel();
              }}
            >
              Cancel Payment
            </Button>
          )}
          {noCharges && (
            <Button
              content="Book Appointment"
              loading={requestingConsultation}
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                this.bookAppointment();
              }}
              positive
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      currency: state.user.authSession.currency,
      fetching: state.consultation.fetching,
      doctor: state.consultation.selectedDoctor,
      verifyingVoucher: state.consultation.verifyingVoucher,
      voucherApproved: state.consultation.voucherApproved,
      deduction: state.consultation.percentage_deduction,
      reasons: state.consultation.reasons,
      payment_confirmation: state.consultation.payment_confirmation,
      stage: state.consultation.stage,
      error: state.consultation.error,
      errorMessage: state.consultation.error_message,
      requestingConsultation: state.consultation.requestingConsultation,
      rtc_session_id: state.consultation.rtc_session_id,
      client_secret: state.consultation.client_secret,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(_consultationAppointment, dispatch),
  })
)(Payment);
