import * as types from "../constants/user.js";

const initialState = {
  authSession: null,
  authenticationInProgress: false,
  user_type: null,
  currency: "gbp",
  error: false,
  error_message: null,
  signupMsg: null,
  signupSuccess: false,
  signupRequest: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST: {
      return {
        ...state,
        authenticationInProgress: true,
      };
    }
    case types.LOGIN_SUCCESS: {
      const { details, user_type, currency } = action.data;
      return {
        ...state,
        authenticationInProgress: false,
        authSession: details,
        user_type: user_type,
        currency: currency,
        error: false,
        error_message: null,
      };
    }
    case types.LOGIN_FAILED: {
      return {
        ...state,
        authenticationInProgress: false,
        error: true,
        error_message: action.data,
      };
    }
    case types.RESET_ERROR: {
      return {
        ...state,
        error: false,
        error_message: null,
      };
    }
    case types.SESSION_RESTORED: {
      const { user_detail, user_type } = action.data;
      return {
        ...state,
        authSession: user_detail,
        user_type,
      };
    }

    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        signupMsg: action.data,
        signupSuccess: true,
        signupRequest: false,
      };
    }
    case types.SIGNUP_FAILED: {
      return {
        ...state,
        signupMsg: action.data,
        signupSuccess: false,
        signupRequest: false,
      };
    }

    case types.SIGNUP_REQUEST: {
      return {
        ...state,
        signupRequest: true,
      };
    }

    case types.SIGNUP_RESETMSG: {
      return {
        ...state,
        signupMsg: null,
        signupSuccess: false,
        signupRequest: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;

// {
//   isAuth: false,
//   requestingAuth: false,
//   clearingAuth: false,
//   authSession: null,
//   authError: null,
//   requestingRestore: true,
//   logoutError: '',
//   userdata: null,
//   user_type: null,
//   currency: 'gbp',
//   donationInProgress: false,
//   donationStatus: null,
//   donationFailedMsg: null,
//   overrodeCurrency: false,
//   signupMsg: null,
// }
