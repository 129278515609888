import React from "react";

const Home = () => {
  return (
    <div style={{ marginTop: 20 }}>
      <div>Welcome to Medicine Buddha </div>
    </div>
  );
};

export default Home;
