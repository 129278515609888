import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Stripe from "./Stripe";

import * as consultationActions from "../actions/consultation";
import * as supportActions from "../actions/support";
import {
  Divider,
  Segment,
  Modal,
  Grid,
  Button,
  Header,
  Input,
  Accordion,
  Icon,
  Message,
} from "semantic-ui-react";
import { CURRENCY_SYMBOL } from "../constants/index";

class PayForward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      donation: null,
      activeIndex: 0,
      amount: 0,
      donationComplete: false,
    };
  }

  makeDonation = (data) => {
    const { user, currency, client_secret } = this.props;
    const { donation, amount } = this.state;
    this.props.actions.makeDonation(
      donation,
      client_secret,
      currency,
      amount,
      user.id,
      data
    );
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex, amount } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    if (newIndex === 1) {
      // payment intent
      const { currency } = this.props;

      if (amount !== 0) {
        this.props.actions.getClientSecret(amount, currency);
      }
    }
    this.setState({ activeIndex: newIndex });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.donationStatus !== null) {
      return { activeIndex: 0, donationComplete: true }; // <- this is setState equivalent
    }
    return null;
  }

  render() {
    const {
      userCurrency,
      client_secret,
      donationInProgress,
      donationStatus,
      user,
    } = this.props;
    const { donation, activeIndex, donationComplete } = this.state;
    const currency = userCurrency === "NA" ? user.currency : userCurrency;
    let scholarshipAmount = "5,000",
      internshipAmount = "1,000",
      minimumAmount = "1";

    if (currency === "eur") {
      scholarshipAmount = "5,583";
      internshipAmount = "1,106";
      minimumAmount = "2";
    } else if (currency === "inr") {
      scholarshipAmount = "4,75,000";
      internshipAmount = "95,000";
      minimumAmount = "100";
    }

    const symbol = CURRENCY_SYMBOL[currency];
    const donationStatusDOM =
      donationStatus === "failed" ? (
        <Message negative>
          <Message.Header>Failed whilst trying to make donation</Message.Header>
        </Message>
      ) : donationStatus === "error" ? (
        <Message warning>
          <Message.Header>Donation processed but with warnings.</Message.Header>
        </Message>
      ) : donationStatus !== null ? (
        <Message positive>
          <Message.Header>Donation processed successfully</Message.Header>
        </Message>
      ) : null;

    const paymentModal = (
      <Modal
        size="tiny"
        open={donation !== null}
        onClose={() => this.setState({ donation: null })}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Donation</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input
              placeholder="Enter Donation amount"
              style={{ width: "100%", marginTop: 8 }}
              onChange={(e, { value }) => this.setState({ amount: value })}
            />
            <small>
              Minimum amount is {symbol} {minimumAmount}{" "}
            </small>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Payment
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <Stripe
                  secret={client_secret}
                  onSuccess={(data) => {
                    this.makeDonation(data);
                  }}
                  loading={donationInProgress}
                  onFail={() => console.log(`failed`)}
                />
              </Accordion.Content>
            </Accordion>
            {donationStatusDOM}
          </Modal.Description>
          <Modal.Actions>
            <Divider />
            <Button onClick={() => this.setState({ donation: null })} secondary>
              {donationComplete === true ? `Close` : `Cancel`}
            </Button>
          </Modal.Actions>
        </Modal.Content>
      </Modal>
    );

    return (
      <div style={{ marginTop: 20 }}>
        {paymentModal}
        <h2>PayForward</h2>
        <Divider />
        <p>
          If you feel you benefitted from your consultation, please consider
          donating to our two Pay it Forward initiatives. Please press one of
          the two initiatives and then the donation amount below which then
          proceeds to card details. We will update who has been awarded the
          opportunity you helped create. <br />
          Thank you.
        </p>
        <Divider />
        <Segment placeholder>
          <Grid columns={2} stackable textAlign="center">
            <Divider vertical>Or</Divider>

            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header>Tibetan Medicine College Scholarship</Header>
                <p style={{ textAlign: "left", padding: 16 }}>
                  <strong>
                    {symbol} {scholarshipAmount}
                  </strong>{" "}
                  pays for a Tibetan to attend Tibetan Medical College for five
                  year in Darjeeling, India.
                </p>
                <Button
                  primary
                  onClick={(_) => this.setState({ donation: "TrainTibetan" })}
                >
                  Donate
                </Button>
              </Grid.Column>

              <Grid.Column>
                <Header icon>Tibetan Therapist Internship Stipend</Header>
                <p style={{ textAlign: "left", padding: 16 }}>
                  <strong>
                    {symbol} {internshipAmount}
                  </strong>{" "}
                  pays the living expenses for 1 trainee Psychotherapist to gain
                  6 months clinical work experience in Delhi, India.
                </p>
                <Button
                  primary
                  onClick={(_) => this.setState({ donation: "Donation" })}
                >
                  Donate
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Message info>
          <p>
            <Icon name="info circle" />
            Any amount you can donate is appreciated.
          </p>
        </Message>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      userCurrency: state.user.currency,
      client_secret: state.consultation.client_secret,
      donationInProgress: state.support.donationInProgress,
      donationStatus: state.support.donationStatus,
      donationFailedMsg: state.support.donationFailedMsg,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(
      Object.assign({}, consultationActions, supportActions),
      dispatch
    ),
  })
)(PayForward);
