import * as types from "../constants/shop";
import { BACKEND_API } from "../constants/index";

export function fetchPrescription(user_id) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_PRESCRIPTION,
    });
    const url = `${BACKEND_API}/patient/prescription?patient_id=${user_id}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          if (Array.isArray(res.data) && res.data.length === 0) {
            dispatch({
              type: types.FETCH_PRESCRIPTION_EMPTY,
              message: res.message,
            });
          } else {
            dispatch({
              type: types.FETCH_PRESCRIPTION_SUCCESS,
              data: res.data,
            });
          }
        } else {
          dispatch({
            type: types.FETCH_PRESCRIPTION_FAILED,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_PRESCRIPTION_ERROR,
          data: err,
        });
      });
  };
}

export function getDeliveryOptions() {
  // returns the pricing of medicines
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_DELIVERYOPTIONS_REQUEST,
    });
    const url = `${BACKEND_API}/order/delivery`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: types.FETCH_DELIVERYOPTIONS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_DELIVERYOPTIONS_FAILED,
          data: err,
        });
      });
  };
}

export function getClientSecret(charge, currency) {
  return async (dispatch) => {
    const url = `${BACKEND_API}/get/intent`;
    const formData = new FormData();
    formData.append("charge", charge);
    formData.append("currency", currency);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: types.SHOP_CLIENT_SECRET,
            data: res.data,
          });
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  };
}

export function checkout(
  patientId,
  tokenId,
  address,
  currency,
  cart,
  deliveryOption,
  prescriptionId,
  paymentIntent
) {
  return (dispatch) => {
    dispatch({
      type: types.CHECKOUT_INTIATE,
    });

    // get the payment intent and then trigger the actual order placement

    const formdata = new FormData();
    formdata.append("patient_id", patientId);
    formdata.append("token_id", tokenId);
    formdata.append("address", address);
    formdata.append("currency", currency);
    formdata.append("cart", JSON.stringify(cart));
    formdata.append("delivery_option", deliveryOption);
    formdata.append("prescription_id", prescriptionId);
    formdata.append("payment_intent", JSON.stringify(paymentIntent));
    fetch(`${BACKEND_API}/order`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: types.CHECKOUT_COMPLETE,
            data: res.data,
          });
        } else {
          dispatch({
            type: types.CHECKOUT_FAIL,
            data: "Unable to process this payment. Please try again.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.CHECKOUT_FAIL,
          data: "Failed whilst checking out",
        });
      });
  };
}
