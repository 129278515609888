import * as _consultation from "../constants/consultation";
import { BACKEND_API } from "../constants/index";

export function resetErrorMessage() {
  return async (dispatch) => {
    dispatch({
      type: _consultation.RESET_ERROR,
    });
  };
}

function fetchingDoctorDetail() {
  return {
    type: _consultation.FETCH_DOCTOR_DETAIL_REQUEST,
  };
}

function fetchingDoctors() {
  return {
    type: _consultation.FETCH_AVBL_DOCTOR_REQUEST,
  };
}

export function getAvailableDoctors(doctorType) {
  return async (dispatch) => {
    dispatch(fetchingDoctors);
    if (!doctorType || doctorType === undefined) {
      dispatch({
        type: _consultation.FETCH_AVBL_DOCTOR_FAILED,
        data: "Invalid Doctor request sent",
      });
    }
    const url = `${BACKEND_API}/doctors/?d_type=${doctorType}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: _consultation.FETCH_AVBL_DOCTOR_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: _consultation.FETCH_AVBL_DOCTOR_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.FETCH_AVBL_DOCTOR_FAILED,
          data: err,
        });
      });
  };
}

export function resetDoctorDetail() {
  return async (dispatch) => {
    dispatch({
      type: _consultation.FETCH_DOCTOR_DETAIL_RESET,
    });
  };
}

export function getDoctorDetail(doctor_id) {
  return async (dispatch) => {
    dispatch(fetchingDoctorDetail);
    const url = BACKEND_API + "/doctor/";
    const formdata = new FormData();
    formdata.append("doctor_id", doctor_id);
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: _consultation.FETCH_DOCTOR_DETAIL_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: _consultation.FETCH_DOCTOR_DETAIL_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.FETCH_DOCTOR_DETAIL_FAILED,
          data: err,
        });
      });
  };
}

export function getConsultationStatusPatient(consultationId, patientId) {
  return async (dispatch) => {
    dispatch({
      type: _consultation.PATIENT_CONSULTATION_STATUS,
    });
    const url =
      BACKEND_API +
      `/consultation/status?consultation_id=${consultationId}&patient_id=${patientId}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          if (data.hasOwnProperty("rtc_session") && data.rtc_session !== "") {
            dispatch({
              type: _consultation.PATIENT_CONSULTATION_STATUS_RESPONSE,
              data,
            });
          }
        } else {
          dispatch({
            type: _consultation.PATIENT_CONSULTATION_STATUS_FAIL,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.PATIENT_CONSULTATION_STATUS_ERR,
          data: err,
        });
      });
  };
}

export function createPaymentIntent(tokenId, currency, amount, coupon) {
  return async (dispatch) => {
    dispatch({
      type: _consultation.CONSULTATION_REQUEST,
    });
    const formdata = new FormData();
    formdata.append("tokenId", tokenId);
    formdata.append("currency", currency);
    formdata.append("amount", amount);
    formdata.append("coupon", coupon);
    fetch(`${BACKEND_API}/consultation/payment/`, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "succeeded") {
          dispatch({
            type: _consultation.CONSULTATION_REQUEST_PAYMENT,
            data: response.confirmation,
          });
        } else {
          dispatch({
            type: _consultation.CONSULTATION_REQUEST_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        console.log("payment intent err: ", err);
        dispatch({
          type: _consultation.CONSULTATION_REQUEST_FAILED,
          data: err,
        });
      });
  };
}

export function initiateConsultationRequest(
  patientId,
  doctorId,
  payment_confirmation_code,
  coupon_code,
  reason,
  paymentDetails
) {
  return async (dispatch) => {
    const url = BACKEND_API + `/consultation/primary/`;

    const payment =
      paymentDetails === null ? null : JSON.stringify(paymentDetails);
    const method =
      payment === null ? null : paymentDetails.payment_method_types[0];
    const amount = payment === null ? 0 : paymentDetails.amount / 100;

    const formdata = new FormData();
    formdata.append("doctor", doctorId);
    formdata.append("patient", patientId);
    formdata.append("confirmation_code", payment_confirmation_code);
    formdata.append("coupon", coupon_code !== "" ? coupon_code : false);
    formdata.append("reason", reason);
    formdata.append("payment", payment);
    formdata.append("method", method);
    formdata.append("amount", amount);
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data, message } = response;
          dispatch({
            type: _consultation.CONSULTATION_REQUEST_SENT,
            data: {
              consultation_id: data.consultation_id,
              message,
            },
          });
        } else {
          dispatch({
            type: _consultation.CONSULTATION_REQUEST_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.CONSULTATION_REQUEST_FAILED,
          data: err,
        });
      });
  };
}

export function verifyVoucher(voucher, patient_id) {
  return async (dispatch) => {
    dispatch({
      type: _consultation.VERIFY_VOUCHER_REQUEST,
    });
    const url = `${BACKEND_API}/coupons/verify?patient_id=${patient_id}&coupon_code=${voucher}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: _consultation.VERIFY_VOUCHER_SUCCESS,
            data: {
              percentage: res.data,
              coupon: voucher,
            },
          });
        } else {
          dispatch({
            type: _consultation.VERIFY_VOUCHER_FAIL,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.VERIFY_VOUCHER_ERROR,
          data: err,
        });
      });
  };
}

export function resetVoucher() {
  return async (dispatch) => {
    dispatch({
      type: _consultation.VOUCHER_RESET,
    });
  };
}

export function getReasons(doctor_type) {
  return async (dispatch) => {
    dispatch({
      type: _consultation.GET_REASONS_REQUEST,
    });
    const url = `${BACKEND_API}/get/reasons?doctor_type=${doctor_type}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          const { data } = res;
          const parsed = data.map((_item) => ({
            key: _item.id,
            value: _item.value,
            text: _item.value,
          }));

          const reasons = [
            ...parsed,
            ...[
              {
                id: 0,
                value: "other",
                text: "Other",
              },
            ],
          ];

          dispatch({
            type: _consultation.GET_REASONS_SUCCESS,
            data: reasons,
          });
        } else {
          dispatch({
            type: _consultation.GET_REASONS_FAIL,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.GET_REASONS_ERROR,
          data: "Failed",
        });
      });
  };
}

export function endConsultation(consultationId, duration) {
  return async (dispatch) => {
    const url = `${BACKEND_API}/consultation/end?consultation_id=${consultationId}&session_time=${duration}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: _consultation.CONSULTATION_END_SUCCESS,
            data: { consultationId },
          });
        } else {
          dispatch({
            type: _consultation.CONSULTATION_END_FAILURE,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.CONSULTATION_END_ERROR,
          data: err,
        });
      });
  };
}

export function getClientSecret(charge, currency) {
  return async (dispatch) => {
    const url = `${BACKEND_API}/get/intent`;
    const formData = new FormData();
    formData.append("charge", charge);
    formData.append("currency", currency);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: _consultation.CONSULTATION_CLIENT_SECRET,
            data: res.data,
          });
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  };
}

export function getDoctorFeedback(doctorId) {
  return async (dispatch) => {
    dispatch({ type: _consultation.DOCTOR_FEEDBACK_REQUEST });
    const url = `${BACKEND_API}/get/feedbacks?doctor_id=${doctorId}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: _consultation.DOCTOR_FEEDBACK_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: _consultation.DOCTOR_FEEDBACK_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _consultation.DOCTOR_FEEDBACK_FAILED,
          data: err,
        });
      });
  };
}

export function sendFeedback(consultation, rating, feedback) {
  return async (dispatch) => {
    dispatch({
      type: _consultation.FEEDBACK_REQUEST,
    });
    const formdata = new FormData();
    if (!consultation) {
      dispatch({
        type: _consultation.FEEDBACK_FAILED,
        data: `No Consultation ID found`,
      });
    } else {
      formdata.append("consultation", consultation);
      formdata.append("rating", rating);
      formdata.append("feedback", feedback);
      const url = `${BACKEND_API}/feedback/`;
      fetch(url, {
        method: "POST",
        body: formdata,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            dispatch({
              type: _consultation.FEEDBACK_SENT,
            });
          } else {
            dispatch({
              type: _consultation.FEEDBACK_FAILED,
              data: res.message,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: _consultation.FEEDBACK_ERROR_ON_PARSING,
          });
        });
    }
  };
}
