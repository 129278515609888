import React, { useMemo } from "react";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "semantic-ui-react";
import { S_KEY } from "../constants/index";

const stripePromise = loadStripe(S_KEY);

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const SplitForm = ({ onSuccess, onFail, secret, loading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        type: "card",
        card: elements.getElement(CardNumberElement),
      },
    });

    if (result.error) {
      // console.log(result.error.message);
      onFail(result);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        onSuccess(result.paymentIntent);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card number
        <CardNumberElement options={options} />
      </label>
      <label>
        Expiration date
        <CardExpiryElement options={options} />
      </label>
      <label>
        CVC
        <CardCvcElement options={options} />
      </label>
      <Button content="Make payment" primary loading={loading} />
    </form>
  );
};

export default class Stripe extends React.Component {
  render() {
    const { onSuccess, onFail, secret, loading } = this.props;
    return (
      <Elements stripe={stripePromise}>
        <SplitForm
          onSuccess={onSuccess}
          onFail={onFail}
          secret={secret}
          loading={loading}
        />
      </Elements>
    );
  }
}
