import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Modal,
  Segment,
  Divider,
  Grid,
  Header,
  TextArea,
  Message,
  Rating,
} from "semantic-ui-react";

import Notifier from "../common/Notifier";
import * as _consultationAppointment from "../actions/consultation";

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      rating: 0,
      close: false,
      redirectToDonation: false,
    };
  }
  render() {
    const {
      consultationId,
      // status,
      sendFeedback,
      // _consultation,
      _consultationId,
      _serverFeedbackStatus,
      actions,
    } = this.props;
    const { message, close, rating, redirectToDonation } = this.state;

    if (redirectToDonation) {
      return <Redirect to="/pay-forward" />;
    }
    if (close) {
      return <Redirect to="/" />;
    }
    if (!consultationId && !_consultationId) {
      return (
        <Notifier
          size="small"
          type="warn"
          title="Oops.."
          content={
            <div>
              How did you end up here? Click <Link to="/">here</Link> to go to
              Home
            </div>
          }
        />
      );
    }

    const _consultationIdToUse =
      consultationId !== null ? consultationId : _consultationId;

    return (
      <Modal
        size="small"
        onClose={() => {
          this.setState({ close: true });
        }}
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Feedback</Modal.Header>
        <Modal.Content>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical>Or</Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Header>Feedback</Header>
                  {_serverFeedbackStatus === "sent" && (
                    <Message positive>Successfully sent feedback</Message>
                  )}
                  {_serverFeedbackStatus !== "sent" && (
                    <>
                      <Rating
                        icon="star"
                        defaultRating={0}
                        maxRating={5}
                        onRate={(e, { rating }) => {
                          this.setState({ rating });
                        }}
                        size="large"
                        value={rating}
                      />
                      <TextArea
                        placeholder="Enter your feedback.."
                        style={{
                          minHeight: 100,
                          width: "100%",
                          marginTop: 8,
                          marginBottom: 8,
                        }}
                        onChange={(e, { value }) => {
                          this.setState({ message: value });
                        }}
                      />
                      <Button
                        color="black"
                        loading={_serverFeedbackStatus === "pending"}
                        onClick={(_) => {
                          if (sendFeedback) {
                            sendFeedback(_consultationIdToUse, rating, message);
                          } else {
                            actions.sendFeedback(
                              _consultationIdToUse,
                              rating,
                              message
                            );
                          }
                        }}
                      >
                        Send Feedback
                      </Button>
                    </>
                  )}
                </Grid.Column>

                <Grid.Column>
                  <Header icon>Pay it Forward</Header>
                  <p style={{ textAlign: "left", padding: 16 }}>
                    If you feel you have benefitted from this consultation, Pay
                    It Forward
                  </p>
                  <Button
                    primary
                    onClick={() => {
                      this.setState({ redirectToDonation: true });
                    }}
                  >
                    Click here
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {_serverFeedbackStatus === "failed" && (
            <Message negative>
              Error while sending feedback. Please try again, if it repeats
              kindly contact <Link to="/support">support</Link>.
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {
      _consultation: state.consultation,
      consultationId: state.consultation.consultationId,
      _consultationId: state.consultation.prevConsultationId,
      _serverFeedbackStatus: state.consultation.feedbackStatus,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(_consultationAppointment, dispatch),
  })
)(Feedback);
