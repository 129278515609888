export const SET_AVAILABILITY_REQUEST = "SET_AVAILABILITY_REQUEST";
export const SET_AVAILABILITY_SUCCESS = "SET_AVAILABILITY_SUCCESS";
export const SET_AVAILABILITY_FAILED = "SET_AVAILABILITY_FAILED";

export const CHECK_CONSULTATION_REQUEST = "CHECK_CONSULTATION_REQUEST";
export const CHECK_CONSULTATION_SUCCESS = "CHECK_CONSULTATION_SUCCESS";
export const CHECK_CONSULTATION_FAILED = "CHECK_CONSULTATION_FAILED";

export const SET_AVAILABILITY_VIA_LOGIN = "SET_AVAILABILITY_VIA_LOGIN";

export const INCOMING_CONSULTATION_REQ_SUCCESS =
  "INCOMING_CONSULTATION_REQ_SUCCESS";
export const INCOMING_CONSULTATION_REQ_FAIL = "INCOMING_CONSULTATION_REQ_FAIL";
export const INCOMING_CONSULTATION_REQ_ERROR =
  "INCOMING_CONSULTATION_REQ_ERROR";

export const DOCTOR_CONSULTATION_STATUS_RESPONSE =
  "DOCTOR_CONSULTATION_STATUS_RESPONSE";
export const DOCTOR_CONSULTATION_STATUS_FAIL =
  "DOCTOR_CONSULTATION_STATUS_FAIL";
export const DOCTOR_CONSULTATION_STATUS_ERR = "DOCTOR_CONSULTATION_STATUS_ERR";
export const DOCTOR_CONSULTATION_STATUS = "DOCTOR_CONSULTATION_STATUS";

export const PAST_PATIENTS_RESPONSE = "PAST_PATIENTS_RESPONSE";
export const PAST_PATIENTS_FAIL = "PAST_PATIENTS_FAIL";
export const PAST_PATIENTS_ERR = "PAST_PATIENTS_ERR";

export const ACCEPT_CONSULTATION_SUCCESS = "ACCEPT_CONSULTATION_SUCCESS";
export const ACCEPT_CONSULTATION_FAILED = "ACCEPT_CONSULTATION_FAILED";
export const ACCEPT_CONSULTATION_ERROR = "ACCEPT_CONSULTATION_ERROR";

export const CONSULTATION_END_SUCCESS = "CONSULTATION_END_SUCCESS";
export const CONSULTATION_END_FAILURE = "CONSULTATION_END_FAILURE";
export const CONSULTATION_END_ERROR = "CONSULTATION_END_ERROR";

export const PRESCRIPTION_SETUP = "PRESCRIPTION_SETUP";
export const PRESCRIPTION_DESTROY = "PRESCRIPTION_DESTROY";
export const PRESCRIPTION_FAILED = "PRESCRIPTION_FAILED";

export const FETCH_INVENTORY_REQUEST = "FETCH_INVENTORY_REQUEST";
export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_FAILED = "FETCH_INVENTORY_FAILED";

export const SEND_PRESCRIPTION = "SEND_PRESCRIPTION";
export const SEND_PRESCRIPTION_SUCCESS = "SEND_PRESCRIPTION_SUCCESS";
export const SEND_PRESCRIPTION_FAIL = "SEND_PRESCRIPTION_FAIL";
export const SEND_PRESCRIPTION_ERROR = "SEND_PRESCRIPTION_ERROR";

export const RESET_SESSION = "RESET_SESSION";
export const RESET_PRESCRIPTION = "RESET_PRESCRIPTION";
