// AUTHENTICATE EVENTS
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const RESTORE_REQUEST = "RESTORE_REQUEST";
export const RESTORE_FAILED = "RESTORE_FAILED";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_RESETMSG = "SIGNUP_RESETMSG";

export const RESET_ERROR = "RESET_ERROR";
export const SESSION_RESTORED = "SESSION_RESTORED";
