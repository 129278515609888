import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk";

import { Radio, Message, Modal, Button } from "semantic-ui-react";
import { APPID } from "../constants/index";
import { Link } from "react-router-dom";
import Notifier from "../common/Notifier";

AgoraRTC.Logger.setLogLevel(4);
let client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "h264",
  areaCode: [AgoraRTC.AREAS.GLOBAL],
});

const USER_ID = Math.floor(Math.random() * 1000000001);

export default class Agora extends Component {
  sessionTimer = false;

  localStream = AgoraRTC.createStream({
    streamID: USER_ID,
    audio: true,
    video: true,
    screen: false,
  });

  state = {
    remoteStreams: [],
    publishErrorCount: 0,
    timer: 0,
    streamFallback: false,
    peerJoined: false,
    peerLeft: false,
    canEndConsultation: false,
    conferenceEnded: false,
    redirectDoc: false,
  };

  componentDidMount() {
    this.initLocalStream();
    this.initClient();
    const cmpt = this;
    if (this.props.channel) {
      if (this.props.user_type === "patient") {
        setTimeout(() => {
          cmpt.joinChannel();
        }, 2000);
      } else {
        cmpt.joinChannel();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.channel !== this.props.channel && this.props.channel !== "") {
      this.joinChannel();
    }
    if (prevProps.leaveCall !== this.props.leaveCall) {
      this.endCall();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.leaveCall === !prevState.peerLeft) {
      return {
        peerLeft: true,
      };
    }
    return null;
  }

  endCall = () => {
    const { callEnded } = this.props;
    const { timer } = this.state;
    clearInterval(this.sessionTimer);
    this.sessionTimer = false;
    try {
      this.localStream.close();
      client.leave(
        function () {
          callEnded(timer);
        },
        function (err) {
          console.error(`error while leaving channel`, err);
        }
      );
      this.setState({ canEndConsultation: false, conferenceEnded: true });
    } catch (err) {
      console.error("errored", err);
    }
  };

  initLocalStream = () => {
    let me = this;
    me.localStream.init(
      function () {
        me.localStream.play("agora_local");
      },
      function (err) {
        console.error("getUserMedia failed", err);
      }
    );
  };

  initClient = () => {
    client.init(
      APPID,
      function () {
        console.log("AgoraRTC client initialized");
      },
      function (err) {
        console.error("AgoraRTC client init failed", err);
      }
    );
    this.subscribeToClient();
  };

  onPeerJoined = (ev) => {
    this.setState({
      peerJoined: true,
    });
    if (!this.sessionTimer) {
      const cmpt = this;
      this.sessionTimer = setInterval(function () {
        const { timer } = cmpt.state;
        cmpt.setState({
          timer: timer + 1,
        });
      }, 1000);
    }
  };

  subscribeToClient = () => {
    let me = this;
    client.on("stream-added", me.onStreamAdded);
    client.on("stream-subscribed", me.onRemoteClientAdded);
    client.on("peer-online", me.onPeerJoined);
    client.on("stream-removed", me.onStreamRemoved);
    client.on("peer-leave", me.onPeerLeave);
    client.on("stream-fallback", me.onStreamFallback);
  };

  onStreamFallback = (evt) => {
    this.setState({
      streamFallback: true,
    });
  };

  onStreamAdded = (evt) => {
    let me = this;
    let stream = evt.stream;
    me.setState(
      {
        remoteStreams: {
          ...me.state.remoteStream,
          [stream.getId()]: stream,
        },
      },
      () => {
        // Subscribe after new remoteStreams state set to make sure
        // new stream dom el has been rendered for agora.io sdk to pick up
        client.subscribe(stream, function (err) {
          console.error("Subscribe stream failed", err);
        });
      }
    );
  };

  handlePublishError = () => {
    const me = this;
    me.initLocalStream();
    client.publish(me.localStream, function (err) {
      console.error(`publish errored out again, `, err);
      setTimeout(function () {
        const { publishErrorCount } = me.state;
        if (publishErrorCount < 10) {
          me.handlePublishError();
          me.setState({ publishErrorCount: publishErrorCount + 1 });
        }
      }, 2000);
    });
    // this.joinChannel();
  };

  joinChannel = () => {
    let me = this;
    client.join(
      null,
      me.props.channel,
      USER_ID,
      function (uid) {
        client.enableDualStream();
        // client.setStreamFallbackOption(1);
        client.publish(me.localStream, function (err) {
          me.handlePublishError();
          console.error("Publish local stream error: " + err);
        });
      },
      function (err) {
        console.error("Join channel failed", err);
      }
    );
  };

  onRemoteClientAdded = (evt) => {
    let me = this;
    let remoteStream = evt.stream;
    me.state.remoteStreams[remoteStream.getId()].play(
      "agora_remote " + remoteStream.getId()
    );
  };

  onStreamRemoved = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;
      stream.stop();
      delete remoteStreams[streamId];
      me.setState({ remoteStreams });
    }
  };

  onPeerLeave = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;
      stream.stop();
      delete remoteStreams[streamId];
      me.setState({ remoteStreams });
    }
    clearInterval(me.sessionTimer);
    this.setState({ peerLeft: true });
    if (me.props.user_type === "doctor") {
      this.props.resetDoctorSession();
    }
  };

  render() {
    const {
      timer,
      peerLeft,
      streamFallback,
      canEndConsultation,
      conferenceEnded,
      redirectDoc,
    } = this.state;

    if (redirectDoc) {
      window.location = "/";
      return null;
    }

    const { doctor_type, user_type, callEnded } = this.props;
    const sessionUpperLimit = doctor_type === "TIB_DOC" ? 1500 : 3000;
    const session_duration = new Date(1000 * timer).toISOString().substr(11, 8);
    const timerStyle =
      timer >= sessionUpperLimit
        ? { fontSize: 20, fontWeight: "bold", color: "red", float: "right" }
        : { fontSize: 20, float: "right" };
    let content = (
      <div className="ui grid">
        <div
          id="agora_local"
          className="eight wide column"
          style={{ height: "75vh" }}
          // style={{ width: "200px", height: "200px", float: 'left' }}
        />
        {Object.keys(this.state.remoteStreams).map((key) => {
          let stream = this.state.remoteStreams[key];
          let streamId = stream.getId();
          return (
            <div
              key={streamId}
              id={`agora_remote ${streamId}`}
              className="eight wide column"
              style={{ height: "75vh" }}
            />
          );
        })}
      </div>
    );

    const showCallEndedElements = peerLeft || conferenceEnded;
    if (showCallEndedElements) {
      if (user_type === "doctor") {
        this.localStream.close();
        client.leave();
        return (
          <Message info>
            <Message.Header>Consultation Ended</Message.Header>
            <p>
              Click <Link to="/">here</Link> to go to Home.
            </p>
          </Message>
        );
      }
      content = (
        <div style={{ height: "17vh" }}>
          <Message positive>
            <Message.Header>
              The other party has left the conversation.
            </Message.Header>
            {doctor_type === "TIB_DOC" && (
              <p>
                Please check the Supplements Section in a few minutes if your
                Tibetan Medicine doctor has prescribed any suggested
                supplements.
              </p>
            )}
            <p>
              Please check out the Pay it Forward button on our home screen if
              you feel you have benefited in any way.
              <br /> Thank you.
            </p>
          </Message>
        </div>
      );
    }
    const modalSize = showCallEndedElements ? "small" : "fullscreen";
    const heightStyle = showCallEndedElements
      ? { height: "17vh" }
      : { height: "89vh" };
    const connectivityMessageStyle = {
      fontSize: 12,
      color: "red",
      marginLeft: 16,
    };
    return (
      <Modal
        onClose={() => console.log(`end call on closing modal`)}
        open={true}
        size={modalSize}
        style={heightStyle}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          Appointment
          {streamFallback && (
            <span style={connectivityMessageStyle}>
              Poor internet connectivity detected.
            </span>
          )}
          <span style={timerStyle}>{session_duration}</span>
        </Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        {showCallEndedElements && user_type === "doctor" && (
          <Notifier
            size="small"
            type="warn"
            title="Consultation Ended"
            content={
              <div>
                Your participant has left the session. Click OK to close the
                session.
              </div>
            }
            onOK={() => {
              this.endCall();
            }}
          />
        )}
        {!showCallEndedElements && (
          <Modal.Actions>
            <Radio
              label="Confirm End Session"
              toggle
              checked={canEndConsultation}
              onChange={() => {
                this.setState({ canEndConsultation: !canEndConsultation });
              }}
            />
            <Button
              content="End Session"
              labelPosition="right"
              disabled={!canEndConsultation}
              icon="checkmark"
              onClick={this.endCall}
              positive
            />
          </Modal.Actions>
        )}
        {showCallEndedElements && user_type !== "doctor" && (
          <Modal.Actions>
            <Button
              onClick={() => {
                this.localStream.close();
                client.leave(
                  function () {
                    callEnded(timer);
                  },
                  function (err) {
                    console.error(`error while leaving channel`, err);
                  }
                );
              }}
              primary
            >
              Go to feedback
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}
