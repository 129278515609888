import * as types from "../constants/doctor";
import { BACKEND_API } from "../constants/index";

export function changeAvailability(docId, nextState) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_AVAILABILITY_REQUEST,
    });

    const url =
      BACKEND_API +
      `/doctor/availability?doctorId=${docId}&status=${nextState}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          dispatch({
            type: types.SET_AVAILABILITY_SUCCESS,
            data: nextState,
          });
        } else {
          dispatch({
            type: types.SET_AVAILABILITY_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.SET_AVAILABILITY_FAILED,
          data: err,
        });
      });
  };
}

export function checkAppointmentRequest(docId) {
  return async (dispatch) => {
    const url = BACKEND_API + `/consultation/incoming?doctor_id=${docId}`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          const { data } = res;
          let dataToSend = data;
          if (Array.isArray(data) && data.length === 0) {
            dataToSend = res.message;
          }
          dispatch({
            type: types.INCOMING_CONSULTATION_REQ_SUCCESS,
            data: dataToSend,
          });
        } else {
          dispatch({
            type: types.INCOMING_CONSULTATION_REQ_FAIL,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.INCOMING_CONSULTATION_REQ_ERROR,
          data: err,
        });
      });
  };
}

export function getPastPatients(docId) {
  return async (dispatch) => {
    const url = BACKEND_API + `/consultation/past?doctor_id=${docId}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: types.PAST_PATIENTS_RESPONSE,
            data,
          });
        } else {
          dispatch({
            type: types.PAST_PATIENTS_FAIL,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.PAST_PATIENTS_ERR,
          data: err,
        });
      });
  };
}

export function getConsultationStatusDoctor(consultationId, docId) {
  return async (dispatch) => {
    dispatch({
      type: types.DOCTOR_CONSULTATION_STATUS,
    });
    const url =
      BACKEND_API +
      `/consultation/status?consultation_id=${consultationId}&doctor_id=${docId}`;
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: types.DOCTOR_CONSULTATION_STATUS_RESPONSE,
            data,
          });
        } else {
          dispatch({
            type: types.DOCTOR_CONSULTATION_STATUS_FAIL,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.DOCTOR_CONSULTATION_STATUS_ERR,
          data: err,
        });
      });
  };
}

export function acceptConsultationRequest(consultationId, docId) {
  return async (dispatch) => {
    const url = `${BACKEND_API}/consultation/accept/`;
    const formdata = new FormData();
    formdata.append("consultation_id", consultationId);
    formdata.append("doctor_id", docId);
    formdata.append("web", 1);
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        const { status, data, message } = response;

        if (status === "success") {
          if (data.hasOwnProperty("rtc_session")) {
            const { rtc_session, patient } = data;
            dispatch({
              type: types.ACCEPT_CONSULTATION_SUCCESS,
              data: {
                rtc_session,
                patient,
              },
            });
          } else {
            dispatch({
              type: types.ACCEPT_CONSULTATION_FAILED,
              data: `Not able to create a conference session`,
            });
          }
        } else {
          dispatch({
            type: types.ACCEPT_CONSULTATION_FAILED,
            data: message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.ACCEPT_CONSULTATION_ERROR,
          data: err,
        });
      });
  };
}

export function getInventory(consultation_id, name) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_INVENTORY_REQUEST,
      data: {
        id: consultation_id,
        name,
      },
    });
    const url = `${BACKEND_API}/inventory`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: types.FETCH_INVENTORY_SUCCESS,
            data: res.data,
          });
        } else {
          dispatch({
            type: types.FETCH_INVENTORY_FAILED,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_INVENTORY_FAILED,
          data: err,
        });
      });
  };
}

export function createPrescription(
  consultationId,
  doctorId,
  prescription,
  instructions
) {
  return async (dispatch) => {
    dispatch({
      type: types.SEND_PRESCRIPTION,
    });
    const url = `${BACKEND_API}/prescription/create`;
    const formdata = new FormData();
    formdata.append("doctor_id", doctorId);
    formdata.append("consultation_id", consultationId);
    formdata.append("inventory", JSON.stringify(prescription));
    formdata.append("instructions", instructions);

    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: types.SEND_PRESCRIPTION_SUCCESS,
            data: res.data,
          });
        } else {
          dispatch({
            type: types.SEND_PRESCRIPTION_FAIL,
            data: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: types.SEND_PRESCRIPTION_ERROR,
          data: "Failed whilst creating a prescription",
        });
      });
  };
}

export function resetDoctorSession() {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_SESSION,
    });
  };
}

export function resetPrescription() {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_PRESCRIPTION,
    });
  };
}
