import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as docActions from "../actions/doctor";
import { Redirect } from "react-router-dom";
import {
  Divider,
  Button,
  Dropdown,
  Grid,
  Item,
  Feed,
  TextArea,
} from "semantic-ui-react";

import Notifier from "../common/Notifier";

class Prescribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMed: null,
      prescription: {},
      instructions: "",
    };
  }

  prescriptionFormat = (obj) => {
    obj.strength = 0;
    obj.dosage = 0;
    return obj;
  };

  addToPrescription = (id) => {
    const { inventory } = this.props;
    let { prescription } = this.state;
    const med = this.prescriptionFormat(
      inventory.filter((x) => x.id === id)[0]
    );
    med.strength = 0;
    med.dosage = 0;
    prescription[id] = med;
    this.setState({
      prescription,
    });
  };

  sendPrescription = () => {
    const { user, consultationId, actions } = this.props;
    const { prescription, instructions } = this.state;

    const processedPrescription = Object.keys(prescription).map(
      (i) => prescription[i]
    );

    actions.createPrescription(
      consultationId,
      user.id,
      processedPrescription,
      instructions
    );
  };
  render() {
    const {
      consultationId,
      fetching,
      inventory,
      prescription_status,
      actions,
    } = this.props;
    const { prescription } = this.state;

    if (!consultationId) {
      return <Redirect to="/" />;
    }
    if (inventory.length === 0) {
      this.props.actions.getInventory(consultationId);
    }

    // const parsedInventory = inventory.map((item) => ({
    //   key: item.id,
    //   value: item.id,
    //   text: item.name,
    // }));

    // <Dropdown
    //   placeholder="Search or select Medicine"
    //   fluid
    //   search
    //   selection
    //   options={parsedInventory}
    //   onChange={(_, { value }) => {
    //     this.setState({ selectedMed: value });
    //   }}
    // />

    // if (selectedMed) {
    //   const val = inventory.filter((x) => x.id === selectedMed);
    //   console.log(`med: `, val);
    // }

    const strengthOptions = [
      {
        key: 1,
        value: 1,
        text: "1",
      },
      {
        key: 2,
        value: 2,
        text: "2",
      },
      {
        key: 3,
        value: 3,
        text: "3",
      },
    ];
    const dosageOptions = [
      {
        key: 1,
        value: 1,
        text: "1 month",
      },
      {
        key: 2,
        value: 2,
        text: "2 month",
      },
      {
        key: 3,
        value: 3,
        text: "3 month",
      },
    ];

    return (
      <div style={{ marginTop: 20 }}>
        {prescription_status === "Successfully created a new prescription" && (
          <Notifier
            size="small"
            type="info"
            title="Success"
            content={prescription_status}
            onOK={actions.resetPrescription}
          />
        )}
        <Grid divided="vertically" columns={2}>
          <Grid.Column>
            <h2>Inventory</h2>
            <Item.Group divided>
              {inventory.length > 1 &&
                inventory.map((item) => {
                  const img =
                    item.images.length === 1 ? item.images[0].source : null;
                  return (
                    <Item key={item.id}>
                      <Item.Image size="tiny" src={img} />
                      <Item.Content>
                        <Item.Header as="a">
                          {item.name} {item.tibetan}
                        </Item.Header>
                        <Item.Meta>{item.description}</Item.Meta>
                        <Item.Extra>
                          <Button
                            size="small"
                            floated="right"
                            onClick={() => this.addToPrescription(item.id)}
                          >
                            Add
                          </Button>
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  );
                })}
            </Item.Group>
          </Grid.Column>
          <Grid.Column>
            <h2>Prescription</h2>
            <Item.Group>
              <Feed>
                {Object.keys(prescription).map((i) => {
                  const item = prescription[i];
                  return (
                    <Feed.Event
                      key={item.id}
                      style={{
                        marginBottom: 10,
                        paddingBottom: 10,
                        borderBottom: "1px solid rgba(34,36,38,.15)",
                      }}
                    >
                      <Feed.Content>
                        <strong>{item.name}</strong>{" "}
                        <strong>{item.tibetan}</strong>
                        <Grid divided="vertically" columns={2}>
                          <Grid.Column>
                            <strong>Strength</strong>
                            <br />
                            <Dropdown
                              placeholder="select strength"
                              options={strengthOptions}
                              onChange={(_, { value }) => {
                                let { prescription } = this.state;
                                prescription[item.id].strength = value;
                                this.setState({ prescription });
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <strong>Dosage</strong>
                            <small>in months</small>
                            <br />
                            <Dropdown
                              placeholder="select strength"
                              options={dosageOptions}
                              onChange={(_, { value }) => {
                                let { prescription } = this.state;
                                prescription[item.id].dosage = value;
                                this.setState({ prescription });
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Feed.Content>
                    </Feed.Event>
                  );
                })}
              </Feed>
            </Item.Group>

            <TextArea
              style={{ minHeight: 100, width: "100%" }}
              placeholder="Instructions"
              onChange={(_, { value }) => {
                this.setState({ instructions: value });
              }}
            />
            <Divider />
            <Button
              primary
              loading={fetching}
              disabled={Object.keys(prescription).length === 0}
              onClick={this.sendPrescription}
            >
              Send Prescription
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      consultationId: state.doctor.prescription_consultationId,
      patientName: state.doctor.prescription_name,
      inventory: state.doctor.inventory,
      fetching: state.doctor.fetching,
      prescription_status: state.doctor.prescription_status,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(docActions, dispatch),
  })
)(Prescribe);
