import * as types from "../constants/shop.js";

const initState = {
  fetching: false,
  inventory: [],
  error: null,
  medicine: null,
  orderId: null,
  deliveryOptions: [],
  prescription: [],
  prescriptionId: null,
  noPrescription: true,
  prescriptionMsg: null,
  client_secret: null,
};

export default function shop(state = initState, action = {}) {
  switch (action.type) {
    // case types.SEND_PRESCRIPTION_RESET: {
    //   return {
    //     ...state,
    //     prescriptionMsg: null,
    //   };
    // }
    // case types.SEND_PRESCRIPTION: {
    //   return {
    //     ...state,
    //     fetching: true,
    //   };
    // }
    // case types.SEND_PRESCRIPTION_SUCCESS: {
    //   return {
    //     ...state,
    //     fetching: false,
    //     prescriptionMsg: action.data,
    //   };
    // }
    // case types.SEND_PRESCRIPTION_FAIL: {
    //   return {
    //     ...state,
    //     fetching: false,
    //     prescriptionMsg: action.data,
    //   };
    // }
    // case types.SEND_PRESCRIPTION_ERROR: {
    //   return {
    //     ...state,
    //     fetching: false,
    //     prescriptionMsg: action.data,
    //   };
    // }
    case types.FETCH_PRESCRIPTION_EMPTY: {
      return {
        ...state,
        fetching: false,
        prescription: [],
        noPrescription: true,
        prescriptionId: null,
      };
    }
    case types.FETCH_PRESCRIPTION: {
      return {
        ...state,
        fetching: true,
        prescription: [],
        prescriptionId: null,
        noPrescription: true,
      };
    }
    case types.FETCH_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        fetching: false,
        prescription: action.data.medicines,
        prescriptionId: action.data.prescription_id,
        noPrescription: false,
      };
    }
    case types.FETCH_PRESCRIPTION_FAILED: {
      return {
        ...state,
        fetching: false,
        prescription: [],
        noPrescription: true,
        error: action.data,
        prescriptionId: null,
      };
    }
    case types.FETCH_PRESCRIPTION_ERROR: {
      return {
        ...state,
        fetching: false,
        prescription: [],
        error: action.data,
        prescriptionId: null,
        noPrescription: true,
      };
    }
    case types.FETCH_INVENTORY_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case types.FETCH_INVENTORY_SUCCESS: {
      return {
        ...state,
        fetching: false,
        inventory: action.data,
      };
    }
    case types.FETCH_INVENTORY_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.data,
      };
    }
    case types.VIEW_INVENTORY: {
      let { inventory, error } = state;
      let medicine = inventory.filter((item) => item.id === action.data);
      if (medicine.length !== 1) {
        error = "Invalid Inventory detail selected";
        medicine = null;
      } else {
        medicine = medicine[0];
      }
      return {
        ...state,
        error,
        medicine,
      };
    }

    case types.CHECKOUT_INTIATE: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.CHECKOUT_COMPLETE: {
      return {
        ...state,
        fetching: false,
        orderId: action.data,
      };
    }

    case types.CHECKOUT_FAIL: {
      return {
        ...state,
        fetching: false,
        error: action.data,
      };
    }

    case types.DISMISS_ORDER: {
      return {
        ...state,
        orderId: null,
      };
    }

    case types.DISMISS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    case types.FETCH_DELIVERYOPTIONS_SUCCESS: {
      return {
        ...state,
        deliveryOptions: action.data,
        fetching: false,
      };
    }
    case types.FETCH_DELIVERYOPTIONS_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case types.FETCH_DELIVERYOPTIONS_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.data,
      };
    }

    case types.SHOP_CLIENT_SECRET: {
      return {
        ...state,
        client_secret: action.data,
      };
    }

    default:
      return state;
  }
}
