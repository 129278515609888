import * as _user from "../constants/user";
import { BACKEND_API } from "../constants/index";

export function logout() {
  localStorage.removeItem("MedBuddha");
}

export function restoreSession(recoveredSession) {
  return async (dispatch) => {
    const { user, user_type } = JSON.parse(recoveredSession);
    dispatch({
      type: _user.SESSION_RESTORED,
      data: {
        user_detail: user,
        user_type,
      },
    });
  };
}

export function login(username, password, user_type) {
  return async (dispatch) => {
    dispatch({
      type: _user.LOGIN_REQUEST,
    });

    const url = BACKEND_API + "/login/";
    const formdata = new FormData();
    formdata.append("email", username);
    formdata.append("password", password);
    formdata.append("user_type", user_type);
    formdata.append("web", 1);
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: _user.LOGIN_SUCCESS,
            data: {
              details: data,
              user_type,
              currency: "NA",
            },
          });
          const session = { user: data, user_type };
          localStorage.setItem("MedBuddha", JSON.stringify(session));
        } else {
          dispatch({
            type: _user.LOGIN_FAILED,
            data: response.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _user.LOGIN_FAILED,
          data: `Failed at Login. Please make sure your credentials are correct and that you have selected correct account type`,
        });
      });
  };
}

export function signup(fname, lname, email, password) {
  return async (dispatch) => {
    dispatch({
      type: _user.SIGNUP_REQUEST,
    });
    const url = BACKEND_API + "/users/create";
    const formdata = new FormData();
    formdata.append("first_name", fname);
    formdata.append("last_name", lname);
    formdata.append("username", email.toLowerCase());
    formdata.append("email", email.toLowerCase());
    formdata.append("password", password);
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          const { data } = response;
          dispatch({
            type: _user.SIGNUP_SUCCESS,
            data,
          });
        } else {
          dispatch({
            type: _user.SIGNUP_FAILED,
            data: response.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: _user.SIGNUP_FAILED,
          data: error,
        });
      });
  };
}
