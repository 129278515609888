export const FETCH_INVENTORY_REQUEST = "FETCH_INVENTORY_REQUEST";
export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_FAILED = "FETCH_INVENTORY_FAILED";

export const VIEW_INVENTORY = "VIEW_INVENTORY";

export const CHECKOUT_INTIATE = "CHECKOUT_INTIATE";
export const CHECKOUT_COMPLETE = "CHECKOUT_COMPLETE";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";

export const DISMISS_ORDER = "DISMISS_ORDER";
export const DISMISS_ERROR = "DISMISS_ERROR";

export const FETCH_DELIVERYOPTIONS_REQUEST = "FETCH_DELIVERYOPTIONS_REQUEST";
export const FETCH_DELIVERYOPTIONS_SUCCESS = "FETCH_DELIVERYOPTIONS_SUCCESS";
export const FETCH_DELIVERYOPTIONS_FAILED = "FETCH_DELIVERYOPTIONS_FAILED";

export const FETCH_PRESCRIPTION = "FETCH_PRESCRIPTION";
export const FETCH_PRESCRIPTION_SUCCESS = "FETCH_PRESCRIPTION_SUCCESS";
export const FETCH_PRESCRIPTION_EMPTY = "FETCH_PRESCRIPTION_EMPTY";
export const FETCH_PRESCRIPTION_FAILED = "FETCH_PRESCRIPTION_FAILED";
export const FETCH_PRESCRIPTION_ERROR = "FETCH_PRESCRIPTION_ERROR";

// export const SEND_PRESCRIPTION = 'SEND_PRESCRIPTION';
// export const SEND_PRESCRIPTION_SUCCESS = 'SEND_PRESCRIPTION_SUCCESS';
// export const SEND_PRESCRIPTION_FAIL = 'SEND_PRESCRIPTION_FAIL';
// export const SEND_PRESCRIPTION_ERROR = 'SEND_PRESCRIPTION_ERROR';
// export const SEND_PRESCRIPTION_RESET = 'SEND_PRESCRIPTION_RESET';

export const SHOP_CLIENT_SECRET = "SHOP_CLIENT_SECRET";
