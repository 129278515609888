import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shop from "../actions/shop";
import {
  Divider,
  Dropdown,
  Message,
  Button,
  Icon,
  Accordion,
  Item,
  Grid,
  Input,
} from "semantic-ui-react";
import { CURRENCY_SYMBOL } from "../constants/index";
import Stripe from "./Stripe";

class Prescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      lineOne: "",
      lineTwo: "",
      city: "",
      pinCode: "",
      province: "",
      country: "",
      paymentIntentStatus: null,
      selectedDeliveryOptionId: 0,
    };
  }

  pushDetailsToServer = (paymentIntent) => {
    const {
      lineOne,
      lineTwo,
      city,
      pinCode,
      province,
      country,
      selectedDeliveryOptionId,
    } = this.state;
    const {
      prescription,
      user,
      currency,
      prescriptionId,
      client_secret,
    } = this.props;
    const patientId = user.id;
    const addressQuery = [
      lineOne,
      lineTwo,
      city,
      pinCode,
      country,
      province,
    ].join(", ");

    const cart = {};
    const items = prescription.map((item) => ({
      inventory_id: item.inventory.id,
      quantity: item.quantity,
      strength: item.strength,
    }));

    cart["items"] = items;
    cart["cost"] = this.getTotalCost();
    this.props.actions.checkout(
      patientId,
      client_secret,
      addressQuery,
      currency,
      cart,
      selectedDeliveryOptionId,
      prescriptionId,
      paymentIntent
    );
  };

  getTotalCost = () => {
    const { prescription, currency, deliveryOptions } = this.props;
    const { selectedDeliveryOptionId } = this.state;
    if (!selectedDeliveryOptionId) {
      return;
    }
    let netTotal = 0;
    const deliveryCharges = deliveryOptions.filter(
      (i) => i.id === selectedDeliveryOptionId
    )[0].charge;
    netTotal += deliveryCharges;
    prescription.forEach((_p) => {
      let medicineCost = 0;
      switch (_p.quantity) {
        case 1:
          medicineCost = _p.inventory.cost[currency].one;
          break;
        case 2:
          medicineCost = _p.inventory.cost[currency].two;
          break;
        case 3:
          medicineCost = _p.inventory.cost[currency].three;
          break;
        default:
          medicineCost = NaN;
      }
      medicineCost = medicineCost * parseInt(_p.strength);
      netTotal += medicineCost;
    });
    return netTotal;
  };

  fetchPrescription = () => {
    const { user, actions } = this.props;
    actions.fetchPrescription(user.id);
  };

  componentDidMount() {
    this.fetchPrescription();
    this.props.actions.getDeliveryOptions();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    if (newIndex === 2) {
      // payment intent
      const { currency } = this.props;
      const charges = this.getTotalCost();
      if (charges !== 0) {
        this.props.actions.getClientSecret(charges, currency);
      }
    }
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const {
      prescription,
      currency,
      deliveryOptions,
      client_secret,
      orderId,
      error,
    } = this.props;

    const {
      activeIndex,
      paymentIntentStatus,
      selectedDeliveryOptionId,
    } = this.state;
    const symbol = CURRENCY_SYMBOL[currency];
    let netTotal = 0;
    const parsedDeliveryOptions = deliveryOptions
      .filter((x) => x.currency === currency)
      .map((_d) => ({
        key: _d.id,
        value: _d.id,
        text: `${_d.description} ${symbol} ${_d.charge}`,
      }));
    const selectedDelivery = deliveryOptions.filter(
      (i) => i.id === selectedDeliveryOptionId
    );
    let deliveryDOM = null;

    if (selectedDelivery.length === 1) {
      netTotal += selectedDelivery[0].charge;
      deliveryDOM = (
        <p>
          Delivery Choice <br />
          <strong>{selectedDelivery[0]["description"]}</strong>
          <span style={{ marginLeft: 20 }}>
            + {symbol} {selectedDelivery[0].charge}
          </span>
        </p>
      );
    }
    const showPrescriptions = prescription.length !== 0 && (
      <>
        <Item.Group>
          {prescription.map((_p) => {
            const img =
              _p.inventory.images.length === 1
                ? _p.inventory.images[0].source
                : null;
            let medicineCost = 0;
            switch (_p.quantity) {
              case 1:
                medicineCost = _p.inventory.cost[currency].one;
                break;
              case 2:
                medicineCost = _p.inventory.cost[currency].two;
                break;
              case 3:
                medicineCost = _p.inventory.cost[currency].three;
                break;
              default:
                medicineCost = NaN;
            }
            medicineCost = medicineCost * parseInt(_p.strength);
            netTotal += medicineCost;
            return (
              <Item key={_p.id}>
                <Item.Image size="tiny" src={img} />
                <Item.Content verticalAlign="middle">
                  <Item.Header as="a">{_p.inventory.name}</Item.Header>
                  <Item.Meta>{_p.inventory.description}</Item.Meta>
                  <Item.Description>
                    <Grid divided="vertically" columns={4}>
                      <Grid.Column>
                        Strength
                        <span style={{ marginLeft: 16 }}>
                          <strong>{_p.strength}</strong>
                        </span>
                      </Grid.Column>
                      <Grid.Column>
                        Duration
                        <span style={{ marginLeft: 16 }}>
                          <strong>{_p.quantity} month</strong>
                        </span>
                      </Grid.Column>
                      <Grid.Column>
                        Cost
                        <span style={{ marginLeft: 16 }}>
                          <strong>
                            {symbol} {medicineCost}
                          </strong>
                        </span>
                      </Grid.Column>
                    </Grid>
                  </Item.Description>
                </Item.Content>
              </Item>
            );
          })}
        </Item.Group>
        <Accordion>
          <Accordion.Title active={true} index={0} onClick={this.handleClick}>
            Postal Address
          </Accordion.Title>
          <Accordion.Content active={true}>
            <Input
              placeholder="Line one"
              style={{ width: "100%", marginTop: 8 }}
              onChange={(e, { value }) => {
                this.setState({ lineOne: value });
              }}
            />
            <Input
              placeholder="Line Two"
              style={{ width: "100%", marginTop: 8 }}
              onChange={(e, { value }) => {
                this.setState({ lineTwo: value });
              }}
            />
            <Input
              placeholder="City"
              style={{ width: "49%", marginTop: 8 }}
              onChange={(e, { value }) => {
                this.setState({ city: value });
              }}
            />
            <Input
              placeholder="Pin code"
              style={{ width: "50%", marginTop: 8, marginLeft: 6 }}
              onChange={(e, { value }) => {
                this.setState({ pinCode: value });
              }}
            />
            <Input
              placeholder="Province"
              style={{ width: "49%", marginTop: 8 }}
              onChange={(e, { value }) => {
                this.setState({ province: value });
              }}
            />
            <Input
              placeholder="Country"
              style={{ width: "50%", marginTop: 8, marginLeft: 6 }}
              onChange={(e, { value }) => {
                this.setState({ country: value });
              }}
            />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            Delivery Options
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <Dropdown
              placeholder="Select Delivery options"
              fluid
              selection
              options={parsedDeliveryOptions}
              onChange={(e, { value }) =>
                this.setState({ selectedDeliveryOptionId: value })
              }
            />
          </Accordion.Content>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            Payment
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <Stripe
              secret={client_secret}
              onSuccess={(data) => {
                this.setState({ paymentIntentStatus: "success" });
                this.pushDetailsToServer(data);
              }}
              loading={false}
              onFail={() => {
                this.setState({ paymentIntentStatus: "failure" });
              }}
            />
          </Accordion.Content>
        </Accordion>
        <Divider />
        <Message info>
          {deliveryDOM}
          <h3>
            Total Charge:{" "}
            <span style={{ marginLeft: 20 }}>
              {symbol} {netTotal}
            </span>
          </h3>
        </Message>
        {orderId && (
          <>
            <Message positive>
              <Message.Header>Order ID: {orderId} </Message.Header>
              <p>Please check your email for invoice and details. Thank you.</p>
            </Message>
            <Button
              primary
              onClick={() => {
                window.location = "/";
              }}
            >
              Home
            </Button>
          </>
        )}
        {(paymentIntentStatus === "failure" || error) && (
          <Message negative>
            <Message.Header>
              There was an issue while completing this transaction.
            </Message.Header>
            <p>
              Please try again. If the subsequent purchase fails again, please
              reach out to the support team. Thank you.
            </p>
          </Message>
        )}
      </>
    );

    const noPrescriptions = prescription.length === 0 && (
      <Message info>
        <Message.Header>No Prescriptions found</Message.Header>
        <p>
          If you believe you should have received a prescription, please give
          our practitioners some time to prescribe.{" "}
        </p>
        <Button primary onClick={this.fetchPrescription} size="tiny">
          <Icon name="refresh" /> Refresh
        </Button>
      </Message>
    );
    return (
      <div>
        <h1>Prescription</h1>
        <Divider />
        {showPrescriptions}
        {noPrescriptions}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      currency: state.user.authSession.currency,
      shop: state.shop,
      prescription: state.shop.prescription,
      prescriptionId: state.shop.prescriptionId,
      deliveryOptions: state.shop.deliveryOptions,
      client_secret: state.shop.client_secret,
      orderId: state.shop.orderId,
      error: state.shop.error,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(shop, dispatch),
  })
)(Prescription);
