import React from "react";
import { Image, Divider } from "semantic-ui-react";

const MenuComponent = () => {
  return (
    <div style={{ marginTop: 30 }}>
      <Divider />
      <Image
        src="/assets/180.png"
        style={{ marginRight: 6, width: 20, float: "left" }}
      />
      <span style={{ float: "right" }}>&copy; Medicine Buddha Limited</span>
    </div>
  );
};

export default MenuComponent;
