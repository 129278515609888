import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Segment, Button, TextArea, Message } from "semantic-ui-react";

import * as supportActions from "../actions/support";

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grievance: "",
    };
  }
  render() {
    const { grievance } = this.state;
    const { supportRequestSent, supportRequestStatus } = this.props;
    return (
      <div style={{ marginTop: 20 }}>
        <h2>Support</h2>
        <Segment>
          <TextArea
            rows={5}
            placeholder="Please provide details"
            style={{ width: "100%", minHeight: 100 }}
            onChange={(e, { value }) => {
              this.setState({ grievance: value });
            }}
          />
          <Button
            primary
            loading={supportRequestSent}
            onClick={() => this.props.actions.raiseSupportTicket(grievance)}
          >
            Submit
          </Button>
          {supportRequestStatus === "success" && (
            <Message success>
              <Message.Header>
                Successfully raised Support request.
              </Message.Header>
              <p>Some one from our team will reach out to you shortly.</p>
            </Message>
          )}
          {supportRequestStatus === "failed" && (
            <Message negative>
              <Message.Header>Error while raising request.</Message.Header>
              <p>
                If this is happening frequently, please send an email to
                teams@medicinebuddha.co.uk
              </p>
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      supportRequestSent: state.support.supportRequestSent,
      supportRequestStatus: state.support.supportRequestStatus,
      supportRequestResponse: state.support.supportRequestResponse,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(supportActions, dispatch),
  })
)(Support);
