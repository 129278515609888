export const FETCH_AVBL_DOCTOR_REQUEST = "FETCH_AVBL_DOCTOR_REQUEST";
export const FETCH_AVBL_DOCTOR_SUCCESS = "FETCH_AVBL_DOCTOR_SUCCESS";
export const FETCH_AVBL_DOCTOR_FAILED = "FETCH_AVBL_DOCTOR_FAILED";

export const FETCH_DOCTOR_DETAIL_REQUEST = "FETCH_DOCTOR_DETAIL_REQUEST";
export const FETCH_DOCTOR_DETAIL_SUCCESS = "FETCH_DOCTOR_DETAIL_SUCCESS";
export const FETCH_DOCTOR_DETAIL_FAILED = "FETCH_DOCTOR_DETAIL_FAILED";
export const FETCH_DOCTOR_DETAIL_RESET = "FETCH_DOCTOR_DETAIL_RESET";

export const CONSULTATION_REQUEST = "CONSULTATION_REQUEST";
export const CONSULTATION_REQUEST_SENT = "CONSULTATION_REQUEST_SENT";
export const CONSULTATION_REQUEST_COMPLETE = "CONSULTATION_REQUEST_COMPLETE";
export const CONSULTATION_REQUEST_ACCEPTED = "CONSULTATION_REQUEST_ACCEPTED";
export const CONSULTATION_REQUEST_PAYMENT = "CONSULTATION_REQUEST_PAYMENT";
export const CONSULTATION_REQUEST_DENIED = "CONSULTATION_REQUEST_DENIED";
export const CONSULTATION_REQUEST_FAILED = "CONSULTATION_REQUEST_FAILED";
export const CONSULTATION_REQUEST_NORESPONSE =
  "CONSULTATION_REQUEST_NORESPONSE";
export const CONSULTATION_REQUEST_NORESPONSE_ERR =
  "CONSULTATION_REQUEST_NORESPONSE_ERR";

export const RESET_CONSULTATION_MESSAGE = "RESET_CONSULTATION_MESSAGE";

export const PATIENT_CONSULTATION_STATUS = "PATIENT_CONSULTATION_STATUS";
export const PATIENT_CONSULTATION_STATUS_RESPONSE =
  "PATIENT_CONSULTATION_STATUS_RESPONSE";
export const PATIENT_CONSULTATION_STATUS_FAIL =
  "PATIENT_CONSULTATION_STATUS_FAIL";
export const PATIENT_CONSULTATION_STATUS_ERR =
  "PATIENT_CONSULTATION_STATUS_ERR";

export const CONSULTATION_END_SUCCESS = "CONSULTATION_END_SUCCESS";
export const CONSULTATION_END_FAILURE = "CONSULTATION_END_FAILURE";
export const CONSULTATION_END_ERROR = "CONSULTATION_END_ERROR";

export const VERIFY_VOUCHER_REQUEST = "VERIFY_VOUCHER_REQUEST";
export const VERIFY_VOUCHER_SUCCESS = "VERIFY_VOUCHER_SUCCESS";
export const VERIFY_VOUCHER_ERROR = "VERIFY_VOUCHER_ERROR";
export const VERIFY_VOUCHER_FAIL = "VERIFY_VOUCHER_FAIL";
export const VOUCHER_RESET = "VOUCHER_RESET";

export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
export const FEEDBACK_SENT = "FEEDBACK_SENT";
export const FEEDBACK_FAILED = "FEEDBACK_FAILED";
export const FEEDBACK_ERROR_ON_PARSING = "FEEDBACK_ERROR_ON_PARSING";
export const FEEDBACK_RESET = "FEEDBACK_RESET";

export const DOCTOR_FEEDBACK_REQUEST = "DOCTOR_FEEDBACK_REQUEST";
export const DOCTOR_FEEDBACK_SUCCESS = "DOCTOR_FEEDBACK_SUCCESS";
export const DOCTOR_FEEDBACK_FAILED = "DOCTOR_FEEDBACK_FAILED";

export const GET_REASONS_REQUEST = "GET_REASONS_REQUEST";
export const GET_REASONS_SUCCESS = "GET_REASONS_SUCCESS";
export const GET_REASONS_FAIL = "GET_REASONS_FAIL";
export const GET_REASONS_ERROR = "GET_REASONS_ERROR";

export const RESET_ERROR = "RESET_ERROR";
export const CONSULTATION_CLIENT_SECRET = "CONSULTATION_CLIENT_SECRET";
