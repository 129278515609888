import React from "react";
import { Card, Image, Button } from "semantic-ui-react";
import { CURRENCY_SYMBOL } from "../constants/index";

const DoctorCard = ({
  id,
  gravatar,
  available,
  first_name,
  last_name,
  charges,
  currency,
  getDetail,
}) => {
  const cardColor = available ? "green" : "yellow";
  const buttonTitle = available ? "Book" : "Detail";
  const chargesToShow =
    charges[currency] === 0
      ? `By Donation`
      : `${CURRENCY_SYMBOL[currency]} ${charges[currency]}`;
  return (
    <Card key={id} color={cardColor}>
      {available && <Image src={gravatar} wrapped size="medium" ui={true} />}
      {!available && (
        <Image src={gravatar} wrapped size="medium" ui={true} disabled />
      )}
      <Card.Content>
        <Card.Header>{[first_name, last_name].join(" ")}</Card.Header>
        <Card.Description>{chargesToShow}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button onClick={getDetail}>{buttonTitle}</Button>
      </Card.Content>
    </Card>
  );
};

export default DoctorCard;
