export const RAISING_TICKET = "RAISING_TICKET";
export const TICKET_RAISED = "TICKET_RAISED";
export const TICKET_FAILED = "TICKET_FAILED";
export const TICKET_ERRORED = "TICKET_ERRORED";
export const TICKET_RESET = "TICKET_RESET";

export const DONATION_REQUEST = "DONATION_REQUEST";
export const DONATION_SUCCESS = "DONATION_SUCCESS";
export const DONATION_FAILED = "DONATION_FAILED";
export const DONATION_RESET = "DONATION_RESET";
