import * as _consultation from "../constants/consultation";

const initState = {
  consultation_type: null,
  availableDoctors: [],
  selectedDoctor: null,
  fetching: false,
  error: false,
  error_message: null,
  verifyingVoucher: null,
  percentage_deduction: 0,
  reasons: [],
  requestingConsultation: false,
  payment_confirmation: null,
  consultationId: null,
  rtc_session_id: null,
  consultationMessage: null,
  stage: null,
  inConsultation: false,
  prevConsultationId: null,
  client_secret: null,
  isfetchingFeedbacks: false,
  doctorFeedbacks: [],
  feedbackStatus: null,
};

const consultation_reducer = (state = initState, action) => {
  switch (action.type) {
    case _consultation.FETCH_AVBL_DOCTOR_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case _consultation.FETCH_AVBL_DOCTOR_SUCCESS: {
      let data = action.data != null ? action.data : [];
      return {
        ...state,
        fetching: false,
        availableDoctors: data,
      };
    }

    case _consultation.FETCH_AVBL_DOCTOR_FAILED: {
      return {
        ...state,
        fetching: false,
        error: true,
        error_message: action.data,
      };
    }

    case _consultation.RESET_ERROR: {
      return {
        ...state,
        error: false,
        error_message: null,
      };
    }

    case _consultation.FETCH_DOCTOR_DETAIL_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case _consultation.FETCH_DOCTOR_DETAIL_SUCCESS: {
      return {
        ...state,
        fetching: false,
        selectedDoctor: action.data,
      };
    }
    case _consultation.FETCH_DOCTOR_DETAIL_FAILED: {
      return {
        ...state,
        fetching: false,
        selectedDoctor: null,
        error: true,
        error_message: action.data,
      };
    }
    case _consultation.FETCH_DOCTOR_DETAIL_RESET: {
      return {
        ...state,
        fetching: false,
        selectedDoctor: null,
        error: false,
        error_message: null,
      };
    }

    case _consultation.VERIFY_VOUCHER_REQUEST: {
      return {
        ...state,
        verifyingVoucher: true,
      };
    }

    case _consultation.VERIFY_VOUCHER_SUCCESS: {
      return {
        ...state,
        verifyingVoucher: false,
        voucherApproved: true,
        percentage_deduction: action.data.percentage,
      };
    }

    case _consultation.VERIFY_VOUCHER_ERROR: {
      return {
        ...state,
        verifyingVoucher: false,
        voucherApproved: false,
        percentage_deduction: 0,
      };
    }

    case _consultation.VERIFY_VOUCHER_FAIL: {
      return {
        ...state,
        verifyingVoucher: false,
        voucherApproved: false,
        percentage_deduction: 0,
      };
    }

    case _consultation.VOUCHER_RESET: {
      return {
        ...state,
        verifyingVoucher: false,
        voucherApproved: null,
        percentage_deduction: 0,
      };
    }

    case _consultation.GET_REASONS_REQUEST: {
      return {
        ...state,
        reasons: [],
      };
    }

    case _consultation.GET_REASONS_SUCCESS: {
      return {
        ...state,
        reasons: action.data,
      };
    }
    case _consultation.GET_REASONS_FAIL: {
      return {
        ...state,
        reasons: [],
      };
    }
    case _consultation.GET_REASONS_ERROR: {
      return {
        ...state,
        reasons: [],
      };
    }

    case _consultation.CONSULTATION_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case _consultation.CONSULTATION_END_SUCCESS: {
      return {
        ...state,
        inConsultation: false,
        rtc_session_id: null,
        stage: "initial",
        hasSentConsultationReq: false,
        prevConsultationId: state.consultationId,
        consultationId: null,
        doctor: null,
        doctors: [],
        doctorFeedbacks: [],
      };
    }

    case _consultation.CONSULTATION_REQUEST_SENT: {
      return {
        ...state,
        requestingConsultation: true,
        consultationId: action.data.consultation_id,
      };
    }

    case _consultation.CONSULTATION_REQUEST_PAYMENT: {
      return {
        ...state,
        requestingConsultation: true,
        fetching: false,
        payment_confirmation: action.data,
        stage: "payment",
      };
    }

    case _consultation.CONSULTATION_REQUEST_FAILED: {
      return {
        ...state,
        fetching: false,
        error: true,
        error_message: action.data,
      };
    }

    // case _consultation.CONSULTATION_REQUEST_ACCEPTED: {
    //   return {
    //     ...state,
    //     requestingConsultation: false,
    //     inConsultation: true,
    //     rtc_session_id: action.data,
    //     stage: "initial",
    //   };
    // }

    case _consultation.CONSULTATION_REQUEST_COMPLETE: {
      return {
        ...state,
        requestingConsultation: false,
        inConsultation: false,
        prevConsultationId: state.consultationId,
        consultationId: null,
        rtc_session_id: null,
        stage: "initial",
      };
    }

    case _consultation.CONSULTATION_REQUEST_NORESPONSE: {
      return {
        ...state,
        inConsultation: false,
        consultationId: null,
        requestingConsultation: false,
        consultationMessage: action.data,
        stage: "unattended",
      };
    }

    case _consultation.PATIENT_CONSULTATION_STATUS_RESPONSE: {
      const { consultation_id, rtc_session } = action.data;
      return {
        ...state,
        consultationId: consultation_id,
        stage: "accepted",
        rtc_session_id: rtc_session,
      };
    }

    case _consultation.CONSULTATION_CLIENT_SECRET: {
      return {
        ...state,
        client_secret: action.data,
      };
    }

    case _consultation.DOCTOR_FEEDBACK_REQUEST: {
      return {
        ...state,
        doctorFeedbacks: [],
        isfetchingFeedbacks: true,
      };
    }
    case _consultation.DOCTOR_FEEDBACK_SUCCESS: {
      return {
        ...state,
        doctorFeedbacks: action.data,
        isfetchingFeedbacks: false,
      };
    }
    case _consultation.DOCTOR_FEEDBACK_FAILED: {
      return {
        ...state,
        doctorFeedbacks: action.message,
        isfetchingFeedbacks: false,
      };
    }

    case _consultation.FEEDBACK_REQUEST: {
      return {
        ...state,
        feedbackStatus: "pending",
      };
    }
    case _consultation.FEEDBACK_SENT: {
      return {
        ...state,
        feedbackStatus: "sent",
      };
    }
    case _consultation.FEEDBACK_FAILED: {
      return {
        ...state,
        feedbackStatus: "failed",
      };
    }
    case _consultation.FEEDBACK_ERROR_ON_PARSING: {
      return {
        ...state,
        feedbackStatus: null,
      };
    }
    case _consultation.FEEDBACK_RESET: {
      return {
        ...state,
        feedbackStatus: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default consultation_reducer;
