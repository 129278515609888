import React, { useState } from "react";
import {
  Item,
  Rating,
  Button,
  Modal,
  Grid,
  Placeholder,
  Popup,
  Header,
  Divider,
  Message,
  TextArea,
} from "semantic-ui-react";
import { BACKEND_API, CURRENCY_SYMBOL } from "../constants/index";

const DoctorProfile = ({
  title,
  data,
  currency,
  open,
  closeProfile,
  book,
  reviews,
  getReviews,
  userId,
}) => {
  const [imageLoading, setImageLoaded] = useState(true);
  const [advanceBook, setAdvanceBook] = useState(false);
  const [requestContent, setRequestContent] = useState("");
  const [showReqStatus, updateReqStatus] = useState(false);
  if (!data) {
    return (
      <Modal
        onClose={closeProfile}
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Placeholder>
            <Placeholder.Line />
          </Placeholder>
        </Modal.Header>
        <Modal.Content>
          <Item.Group>
            <Item>
              <div className="ui medium image">
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </div>
              <Item.Content verticalAlign="middle">
                <Placeholder>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="short" />
                </Placeholder>
                <Item.Meta>
                  <Placeholder>
                    <Placeholder length="medium" />
                  </Placeholder>
                </Item.Meta>
                <Item.Description>
                  <Divider />
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="full" />
                      <Placeholder.Line length="full" />
                      <Placeholder.Line length="full" />
                      <Placeholder.Line length="full" />
                      <Placeholder.Line />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={closeProfile}>
            Close
          </Button>
          <Button
            content="Go to Booking Center"
            labelPosition="right"
            icon="checkmark"
            onClick={book}
            positive
          />
        </Modal.Actions>
      </Modal>
    );
  }
  const {
    name,
    gravatar,
    description,
    charges,
    languages,
    specialization,
    rating,
    feedbacks,
    available,
  } = data;

  const prebook = () => {
    const url = `${BACKEND_API}/consultation/request`;
    const formData = new FormData();
    formData.append("patient_id", userId);
    formData.append("doctor_id", data.id);
    formData.append("message", requestContent);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          if (response.status === "success") {
            updateReqStatus(1);
          } else {
            updateReqStatus(2);
          }
        });
      })
      .catch((err) => {
        updateReqStatus(2);
      });
  };

  const requestResponseMessage =
    showReqStatus === 1 ? (
      <Message info>
        <Message.Header>Thank you</Message.Header>
        <p>The Team has been notified.</p>
      </Message>
    ) : showReqStatus === 2 ? (
      <Message negative>
        <p>
          Unable to raise request. Please reach out to the team via support.
        </p>
      </Message>
    ) : null;

  const userReviews = reviews.map((_r) => (
    <Item key={_r.id}>
      <Item.Content>
        <Rating icon="star" defaultRating={_r.rating} maxRating={5} disabled />
        <Item.Meta>{_r.comment}</Item.Meta>
      </Item.Content>
    </Item>
  ));

  const whoseFee = data.doctor_code === "TIB_DOC" ? "doctor" : "practitioner";
  const sessionLimit = data.doctor_code === "TIB_DOC" ? 30 : 60;

  const feedbackPopup = (
    <Popup
      on="click"
      // onClose={() => {}}
      onOpen={() => {
        getReviews(data.id);
      }}
      popperDependencies={[!!data]}
      trigger={<div style={{ cursor: "pointer" }}>{feedbacks} Feedbacks</div>}
      wide
      position="bottom left"
    >
      {Array.isArray(reviews) && reviews.length === 0 ? (
        <Placeholder style={{ minWidth: "200px" }}>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <>
          <Header as="h2" content="Reviews" />
          <div style={{ height: 200, overflowY: "auto" }}>
            <Item.Group divided>{userReviews}</Item.Group>
          </div>
        </>
      )}
    </Popup>
  );

  const requestAppointmentModal = (
    <Modal
      onClose={() => setAdvanceBook(false)}
      size="tiny"
      open={advanceBook}
      closeOnEscape={true}
      closeOnDimmerClick={true}
    >
      <Modal.Header>Request Consultation - Pre book</Modal.Header>
      <Modal.Content>
        {!requestResponseMessage && (
          <>
            <p>
              Please state date, time and time zone for a Consultation request
              outside the working hours above. Please give us 48 hours notice to
              accommodate your request, which we will facilitate on a best
              endeavours basis. Thank you.
            </p>
            <TextArea
              rows={3}
              style={{ width: "100%" }}
              onChange={(e, { value }) => {
                setRequestContent(value);
              }}
            />
          </>
        )}
        {requestResponseMessage}
      </Modal.Content>
      <Modal.Actions>
        {!showReqStatus && (
          <Button
            content="Send consultation request"
            onClick={prebook}
            positive
          />
        )}
        {showReqStatus && (
          <Button content="Close" onClick={() => setAdvanceBook(false)} />
        )}
      </Modal.Actions>
    </Modal>
  );

  const chargesToShow =
    charges[currency] === 0
      ? `By Donation`
      : `${CURRENCY_SYMBOL[currency]} ${charges[currency]}`;

  return (
    <Modal
      onClose={closeProfile}
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content image>
        <Item.Group>
          <Item>
            <div className="ui medium image">
              {imageLoading && (
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              )}
              <img
                src={gravatar}
                style={{ display: imageLoading ? "none" : "block" }}
                onLoad={() => setImageLoaded(false)}
                alt="Doctor Profile"
              />
            </div>
            <Item.Content verticalAlign="middle">
              <Item.Header as="a">
                <Header>{name}</Header>
                <Rating
                  icon="star"
                  defaultRating={rating}
                  maxRating={5}
                  disabled
                  size="large"
                />
              </Item.Header>
              <Item.Meta>
                {feedbackPopup}
                <Divider />
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                      <span className="strong">Charges</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span className="strong" style={{ fontSize: 16 }}>
                        {chargesToShow}
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <span className="strong">Specialisation</span>
                    </Grid.Column>
                    <Grid.Column>{specialization}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <span className="strong">Languages</span>
                    </Grid.Column>
                    <Grid.Column>{languages}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Meta>
              <Item.Extra></Item.Extra>
              <Item.Description>
                <p>{description}</p>
                <Message info>
                  <p>
                    The {whoseFee} fee above is for a consultation of up to{" "}
                    {sessionLimit} minutes.
                  </p>
                </Message>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={closeProfile}>
          Close
        </Button>
        {available ? (
          <Button
            content="Go to Booking Center"
            labelPosition="right"
            icon="checkmark"
            onClick={book}
            positive
          />
        ) : (
          <Button
            content="Request Consultation"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setAdvanceBook(true)}
            positive
          />
        )}
      </Modal.Actions>

      {requestAppointmentModal}
    </Modal>
  );
};

export default DoctorProfile;
