import React from "react";

const Agreement = () => (
  <>
    <h2 style={{ marginTop: 40 }}>
      Copyright Medicine Buddha 18 May 2020 MEDICINE BUDDHA APP IS NOT FOR
      MEDICAL EMERGENCIES OR URGENT SITUATIONS. IF YOU THINK YOU HAVE A MEDICAL
      EMERGENCY, IMMEDIATELY CALL EMERGENCY SERVICES.
    </h2>
    <p>
      Medicine Buddha app is a private technology company, registered in the
      United Kingdom, which enables patients to hold on-demand and by
      appointment; secure, high-definition, face-to-face, real- time; video,
      telephone consultations with Tibetan Therapists and Tibetan Medicine
      Doctors. The Tibetan Medicine Doctors are registered practitioners under
      the Central Council of Tibetan Medicine, the regulatory body. When a
      practitioner registers with Medicine Buddha App, his or her certificates
      are verified. This is part of the Medicine Buddha App Quality surveillance
      procedures.
    </p>
    <p>
      Medicine Buddha App products are not intended to replace the relationship
      with a patient’s doctor. It is a complementary service offering the
      advantages of convenience and accessibility to a broad range of healthcare
      services.
    </p>
    <h3>INTRODUCTION</h3>
    <p>
      When accessing our Medicine Buddha App Services using Mobile Applications
      and Websites we ask that you make, a few commitments to us to ensure that
      you receive care personalized to you, and to protect you against the risk
      of receiving treatment that is inappropriate or unsafe.
    </p>
    <p>We ask that:</p>
    <ul>
      <li>
        You commit you are the person registering and are not presenting
        yourself as someone else or acting as an agent for somebody else;
      </li>
      <li>
        You will only register once with Medicine Buddha App unless you are
        registering for a dependent or a person in your care;
      </li>
      <li>
        You agree to protect the privacy and security of all Users (Patients,
        Doctors, Our Healthcare Providers and Medicine Buddha App Employees) and
        not to make independent recordings of consultations, capture images, or
        take screen shots or record voice or data or similar. you commit that
        any information you provide Medicine Buddha App and its Healthcare
        Providers will be accurate and not to omit anything of relevance or
        importance, which for the avoidance of doubt includes, current and past,
        medications and conditions;
      </li>
      <li>
        You will comply with all instructions and advice given to you by
        Healthcare Providers, subcontractors and third parties for your
        continuing care;
      </li>
      <li>
        You will comply with the manufacturers’ instructions and guidance as to
        the use of any supplements including method, timing, dosages, potential
        adverse reactions or side effects, expiry dates etc., and the
        instructions of any Tibetan Medicine doctor following a consultation;
        you will promptly report any side effects of any supplement
        recommendation to an appropriate Healthcare Provider; and you agree, If
        you have any concerns or are in any doubt regarding any information or
        Advice you have received or failed to receive via Our Products and
        Services, or regarding your health, wellbeing or any conditions, you
        will immediately seek a further medical opinion from a registered doctor
        or suitable Healthcare Provider not affiliated with Medicine Buddha App
        and/or shall utilise the emergency services as necessary.
      </li>
    </ul>
    <p>
      Definitions and Interpretation In these Terms and Conditions, unless the
      conp otherwise requires, the following expressions have the following
      meanings:
    </p>
    <ul>
      <li>
        1.1. “Medicine Buddha App /Our/Us” means Medicine Buddha App, a Company
        Limited in Liability under the Laws of the European Union State of
        Malta;
      </li>
      <li>
        1.2. “Account” means an account required for a User to access and/or use
        certain areas of Our Apps and Websites;{" "}
      </li>
      <li>
        1.3. “Advice” means any communication, diagnosis, discharge, advice or
        other Services in any form provided or facilitated by Medicine Buddha
        App or its Healthcare Providers through the Apps and Websites;{" "}
      </li>
      <li>
        1.4. “Apps and Websites” means the Application (IOS or Android) or
        Website, including Mobile Website that you are currently using including
        Medicine Buddha.{" "}
      </li>
      <li>
        1.5. “Content” means all files, documents, p, images, audio, video,
        scripts, code, software, databases and any other form of information
        capable of being stored in an application (IOS or Android) or on a
        computer that appears on, or forms part of, Our Apps and Websites;{" "}
      </li>
      <li>
        1.6. “Contract” means a contract for the purchase and sale of Products
        and Services, as explained in Clause 17;
      </li>
      <li>
        1.7. “Data Protection Acts” (DPA) means the General Data Protection
        Regulation (GDPR) and any regulations, codes and guidance issued by the
        Data Protection Commissioner (or any official who may succeed either of
        him or her) and any other applicable data privacy laws and regulations.
        It shall also include any superseding data protection legislation that
        comes into force during the term of this Agreement including Regulation
        2016/649 of the European Parliament and of the Council, General Data
        Protection Regulation (“GDPR”);{" "}
      </li>
      <li>
        1.8. “Personal Data” means the definition given to it in the Data
        Protection Acts (“DPA”) including any information relating to an
        identified or identifiable natural person (“Data Subject”). An
        identifiable natural person is one who can be identified, directly or
        indirectly, in particular, by reference to an identifier such as a name,
        an identification number, location data, an online identifier or to one
        or more factors specific to the physical, physiological, genetic,
        mental, economic, cultural or social identity of that natural person;
        and{" "}
      </li>
      <li>
        1.9 “Healthcare Providers” means any Registered Healthcare Professional
        providing Medicine Buddha App Services through its Apps and Websites.
        The registration is verified with competent National or State
        authorities;
      </li>
      <li>
        1.10. “Order” means your order for the Products and Services which may
        or may not require a payment of monies;{" "}
      </li>
      <li>
        1.11. “Order Confirmation” means Our Medicine Buddha App acceptance and
        confirmation of your Order.{" "}
      </li>
      <li>
        1.12. “Products” means any physical item Ordered including but not
        limited to a Test, Prescription or Activity Device;{" "}
      </li>
      <li>
        1.13. “Products and Services” means the Products and Services provided
        by or via Medicine Buddha App to you as specified in your Orders;{" "}
      </li>
      <li>
        1.14. UK and European Law means the Data Protection Acts and the
        European Union (Consumer Information, Cancellation and Other Rights)
        Regulations 2013 (Si 484/2013), (Distance Selling Regulations).{" "}
      </li>
      <li>1.15. “Services” means any Service to a User including Advice; </li>
      <li>
        1.16. “Third Parties” means any entities providing Products and Services
        through Medicine Buddha App or its Apps and Websites, including
        independent healthcare providers, subcontractors, agencies and suppliers
        other than Medicine Buddha App and its subsidiaries or affiliates;{" "}
      </li>
      <li>
        1.17. “Third Party Apps and Websites” means Apps and Websites not owned
        by Medicine Buddha App or any of its subsidiaries or affiliates;{" "}
      </li>
      <li>
        1.18. “User” means any patient, customer, third party, healthcare
        provider, General Practitioners that accesses the Apps and Websites or
        orders Products and Services from Medicine Buddha App or its
        subsidiaries or affiliates and is not directly employed by Medicine
        Buddha App or acting during their Medicine Buddha App employment; and{" "}
      </li>
      <li>
        1.19. “User Content” means any content submitted to Our Medicine Buddha
        App Apps and Websites by Users including, but not limited to Sensitive
        Personal Data including name, date of birth, state of health, ailments,
        allergies, diseases or disabilities, medications, physical and mental
        characteristics, past medical records, photographs, family information,
        medical diagnoses and notes, details of past consultations; and
        financial information such as credit and debit card numbers.
      </li>
    </ul>
    <h2>END USER LICENCE AGREEMENT FOR OUR APPS AND WEBSITES</h2>
    <p>
      Access to Our Apps and Websites 2.1. You may access Our Apps and Websites
      from anywhere subject to UK and European Law (including the Cross-Border
      Directive) and the applicable laws in your location. It is your
      responsibility to confirm that you comply with, all applicable laws and
      regulations. 2.2. Access to Our Apps and Websites is provided “as is” and
      on an “as available” basis. Medicine Buddha App may alter, suspend or
      discontinue Our Apps and Websites (or any part of it) at any time and
      without notice. Medicine Buddha App will not be liable to you in any way
      if Our Apps and Websites (or any part of it) is unavailable at any time
      and for any period. Accounts 2.3.Certain parts of Our Apps and Websites
      (including the ability to purchase Products and Services may require that
      you create (“register”) an Account to access them. 2.4. You may not
      register an Account if you are under 16 years of age or minimum legal age.
      If you are under 16 years of age or minimum legal age and wish to use
      parts of Our Apps and Websites that require an Account, your parent,
      guardian or carer should register their own “Master” Account and add you
      as Dependant.. 2.5. When creating an Account, the information you provide
      must be accurate and complete. If any of your information changes later,
      you can update it in your Patient Information page in our Apps and
      Websites. Falsely provided information may lead to suspension of the
      Account. 2.6 It is your responsibility to keep your password safe. You
      must not share your password or Account with anyone else. Medicine Buddha
      App will not be liable for any unauthorized access to your Account. 2.7.
      Any personal information you provide or store in your Account will be
      collected, used, and held in accordance with Our data retention policies
      which follow UK/ European Law as appropriate and Industry best practices.
      2.8. If you wish to close your Account, you may do so at any time by
      deleting our app. It will not remove any information we have already
      collected which we are required to maintain in line with Our data
      retention policies, best practice, the relevant Data Protection Acts and
      to use in De-identified form. Intellectual Property Rights and Trade Marks
      3.1.Medicine Buddha App, embodies and is based upon worldwide patented or
      patentable inventions, trade secrets, copyrights and other intellectual
      property rights (collectively “Intellectual Property Rights”) owned by or
      under license by Medicine Buddha App. All Content, including User Content,
      is protected by applicable European Union and International intellectual
      property laws and treaties. 3.2. All logos and trademarks on our Apps and
      Websites are owned by or licensed to Medicine Buddha App. Medicine Buddha
      App hereby reserve all rights to their respective use. User Content 4.1.
      You agree that you will be solely responsible for your User Content.
      Specifically, you agree, represent and warrant that you have the right to
      submit the User Content and that all such User Content will comply with
      Our Acceptable Usage Policy. 4.2. You agree that you will be liable to
      Medicine Buddha App and will, to the full extent permissible by law,
      indemnify Medicine Buddha App for any breach of the warranties given by
      you in these Terms and Conditions. You will be responsible for any loss or
      damage suffered by Medicine Buddha App Limited from a breach. 4.3. You
      retain ownership of your User Content and all intellectual property rights
      subsisting therein. When you submit User Content you grant Medicine Buddha
      App an unconditional, fully transferable, royalty-free, worldwide licence
      to use, store, archive your User Content for the purposes of operating and
      promoting Our Apps and Websites and for providing you with and improving
      Our Medicine Buddha App and Medicine Buddha App healthcare products and
      services including the use of that data in anonymized form. We will not
      share your Personal Data with a third party without your permission other
      than set out in Our Privacy Policy. Disclaimers 5.1. Apps and Websites are
      not suitable for any condition that should reasonably require face to face
      analysis, diagnosis or treatment, or for sourcing any Product and Service
      urgently for a medical emergency or acute condition. 5.2. Medicine Buddha
      App cannot guarantee continuity of care through the same Healthcare
      Provider. 5.3. Medicine Buddha App make no representation, warranty, or
      guarantee that Our Services or Apps and Websites will: Meet your
      requirements; be of satisfactory quality; be fit for a purpose; not
      infringe the rights of third parties; be compatible with all software and
      hardware; or that it will be secure. 5.4. Medicine Buddha App make
      reasonable efforts to ensure that the displayed Content on Our Apps and
      Websites is complete, accurate, and up-to-date. Medicine Buddha App does
      not make any representations, warranties or guarantees (whether express or
      implied) that the Content is complete, accurate, or up-to-date. Our
      Liability 6.1. Medicine Buddha App is not responsible for any loss or
      damage caused by its Healthcare Providers (except to the extent the
      Healthcare Provider is an employee of Medicine Buddha App or acting within
      the scope of their employment), or Third Parties unless caused by the
      negligence, material breach or wilful default of Medicine Buddha App. 6.2.
      To the full extent permissible by law, Medicine Buddha App accepts no
      liability to any user for any loss or damage, whether foreseeable or
      otherwise, in contract, tort (including negligence), for breach of
      statutory duty, or otherwise, arising out of or in connection with the use
      of (or inability to use) Our Apps and Websites or the use of or reliance
      upon any Content included on Our Apps and Websites. 6.3. To the full
      extent permissible by law, Medicine Buddha App exclude all
      representations, warranties, and guarantees (whether express or implied)
      that may apply to Our Apps and Websites or any Content included on Our
      Apps and Websites. 6.4. Medicine Buddha App takes all reasonable steps to
      ensure that Our Apps and Websites are free from viruses and other malware,
      however Medicine Buddha App accepts no liability for any loss or damage
      resulting from a virus or other malware, a distributed denial of service
      attack, or other harmful material or event that may adversely affect your
      hardware, software, data or other material that occurs as a result of your
      use of Our Apps and Websites (including the downloading of any Content
      from it) or any other Apps and Websites referred to on Our Apps and
      Websites. 6.5. Medicine Buddha App neither assume nor accepts
      responsibility or liability arising out of any disruption or
      non-availability of Our Apps and Websites resulting from external causes
      including, but not limited to, ISP equipment failure, host equipment
      failure, communications network failure, natural events, acts of war, or
      legal restrictions and censorship. 6.6. The liability of the Company from
      its Products and Services whether under contract, tort/delict, statute,
      common law or otherwise (and including for negligence or wilful default)
      shall not in any circumstances exceed: the legal minimum in aggregate in
      relation to any products; and the legal minimum in aggregate otherwise.
      Nothing in these Terms and Conditions excludes or restricts Our liability
      for fraud or fraudulent misrepresentation, for death or personal injury
      resulting from negligence, or for any other forms of liability, which
      cannot be excluded or restricted by law. Your Liability 7.1. You commit
      that you are the person registering and not presenting yourself as someone
      else or acting as an Agent or, on behalf of somebody else. 7.2. You will
      only register once with Medicine Buddha App unless you are registering for
      a dependant or a person in your care. 7.3. You agree to protect the
      privacy and security of all Users (Patients, registered Doctors, all Our
      Healthcare Providers and Medicine Buddha App Employees) and not to make
      independent recordings of consultations, to capture images or take screen
      shots or similar. 7.4. You commit that any information you provide
      Medicine Buddha App and its Healthcare Providers will be accurate and not
      to omit anything of relevance or importance which for the avoidance of
      doubt includes, current and past, medications and conditions. 7.5. That
      you will comply with all instructions and advice given to you by
      Healthcare Providers, subcontractors and third parties for your continuing
      care. 7.6. That you will comply with the manufacturers’ instructions and
      guidance as to the use of any medicine (OTC or Prescription) including
      method, timing, dosages, potential adverse reactions or side effects,
      expiry dates etc., and the instructions of any Pharmacist dispensing
      because of, a Medicine Buddha App appointment or consultation. 7.7. That
      you will promptly report any side effects of any prescription to an
      appropriate Healthcare Provider. 7.8. If you have any concerns or are in
      any doubt regarding any information or Advice you have received or failed
      to receive via Our Products and Services, or regarding your health,
      wellbeing or any conditions, you will immediately seek a further medical
      opinion from a registered GP or suitable Healthcare Provider not
      affiliated with Medicine Buddha App and/or shall utilise the emergency
      services as necessary. 7.9.To the full extent permissible by law, Medicine
      Buddha App accept no liability to any user for any loss or damage, whether
      foreseeable or otherwise, in contract, tort (including negligence), for
      breach of statutory duty, or otherwise, arising out of or about the use of
      (or inability to use) Our Apps and Websites or the use of or reliance upon
      any Content included on Our Apps and Websites. Viruses, Malware and
      Security 8.1.Medicine Buddha App take all reasonable steps to ensure that
      Our Apps and Websites are secure and free from viruses and malware.
      Medicine Buddha App do not, however, guarantee that Our Apps and Websites
      are secure or free from viruses or other malware and accept no liability
      in respect of the same. 8.2.You are responsible for protecting your
      hardware, software, data and other material from viruses, malware, and
      other internet security risks. You must not deliberately introduce viruses
      or other malware, or any other material, which is malicious or
      technologically harmful either to or via Our Apps and Websites. 8.3.You
      must not attempt to gain unauthorized access to any part of Our Apps and
      Websites, the server on which Our Apps and Websites is stored, or any
      other server, computer, or database connected to Our Apps and Websites.
      8.4.You must not attack Our Apps and Websites by means of a denial of
      service attack, a distributed denial of service attack, or by any other
      means. Acceptable Usage Policy 9.1.You may only use Our Apps and Websites
      in a manner that is lawful and that complies with our policies.
      9.2.Specifically when submitting User Content (or communicating in any
      other way using Our Apps and Websites), you must not submit, communicate
      or otherwise do anything that is; sexually explicit; obscene, deliberately
      offensive, hateful or otherwise inflammatory; promotes violence; promotes
      or assists in any form of unlawful activity; discriminates against, is in
      any way defamatory of, any person, group or class of persons, race, sex,
      religion, nationality, disability, sexual orientation, age, political
      beliefs or membership of “trade” organizations; is intended or otherwise
      likely to threaten, harass, annoy, alarm, inconvenience, upset, or
      embarrass another person; is calculated or is otherwise likely to deceive;
      is intended or otherwise likely to infringe (or threaten to infringe)
      another person’s right to privacy; misleadingly impersonates any person or
      otherwise misrepresents your identity or affiliation in a way that is
      calculated to deceive. 9.3.You must not infringe, or assist in the
      infringement of, the intellectual property rights (including, but not
      limited to, copyright, patents, trademarks and database rights) of any
      other party; or is in breach of any legal duty owed to a third party
      including, but not limited to, contractual duties and duties of
      confidence. 9.4.Medicine Buddha App reserve the right to suspend or
      terminate your access to Our Apps and Websites without notice if, in Our
      sole opinion, you materially breach the provisions of this Clause or any
      of the other provisions of these Terms and Conditions. 9.5.Medicine Buddha
      App hereby exclude all liability arising out of any actions (including,
      but not limited to those set out above) that Medicine Buddha App may take
      in response to breaches of these Terms and Conditions. Products and
      Services, Pricing and Availability 9.6. Medicine Buddha App make all
      reasonable efforts to ensure that all general descriptions of the Products
      and Services correspond to the actual Products and Services that will be
      provided to you, however please note that the exact nature may vary
      depending upon your individual requirements and circumstances. 9.7. Please
      note that sub-Clause 16.1 does not exclude responsibility for mistakes due
      to negligence on Our part and refers only to variations of the described
      Products and Services, not to different Products and Services altogether.
      9.8.Medicine Buddha App neither represents nor warrant that all Products
      and Services will be always available and cannot necessarily confirm
      availability until your Order is completed. Availability indications are
      not provided on Our Apps and Websites. 9.9. Medicine Buddha App makes all
      reasonable efforts to ensure that all prices shown on Our Apps and
      Websites are correct at the time of going online. Medicine Buddha App
      reserves the right to change prices and to add, alter, or remove special
      offers from time to time and as necessary. All pricing information is
      reviewed and updated regularly. Changes in price will not affect any Order
      that you have already placed. 9.10. Medicine Buddha App checks all prices
      when Medicine Buddha App process your Order. In the unlikely event that
      Medicine Buddha App has shown incorrect pricing information, please note
      the following: Medicine Buddha App will contact you in writing or by
      telephone to inform you of the mistake and to ask you how you wish to
      proceed. Medicine Buddha App will give you the option to purchase the
      Products and Services at the correct price or to cancel your Order (or the
      affected part thereof). Medicine Buddha App will not proceed with
      processing your Order until you respond. If Medicine Buddha App do not
      receive a response from you within 1 day, Medicine Buddha App will treat
      your Order as cancelled and notify you of the same in writing; if the
      price of Products and Services you have ordered changes between your Order
      being placed and Medicine Buddha App processing that Order and taking
      payment, you will be charged the price shown on Our Apps and Websites at
      the time of placing your Order; and all prices on Our Apps and Websites
      include VAT where applicable. If the VAT rate changes between your order
      being placed and Medicine Buddha App taking payment, the amount of VAT
      payable will be automatically adjusted when taking payment. Orders – How
      Contracts Are Formed 10.1.Our Apps and Websites will guide you through the
      ordering process. Before submitting your Order to Medicine Buddha App, you
      will be either requested to confirm your it by accepting Our Terms and
      Conditions (and possibly Privacy Policy and Medical Consent) or given the
      opportunity to review your Order and amend any errors. Please ensure that
      you have checked your Order carefully before submitting it. 10.2. No part
      of Our Apps and Websites constitutes a contractual offer capable of
      acceptance. Your Medicine Buddha App Order constitutes a contractual offer
      that Medicine Buddha App may, at our sole discretion, accept. Our
      acknowledgement of receipt of your Order does not mean that Medicine
      Buddha App has accepted it. Our acceptance is indicated by either your
      entry into a Medicine Buddha App Waiting Room for a consultation with a
      Healthcare Provider where you will also be asked to confirm your payment
      details or by Medicine Buddha App sending you an Order Confirmation by
      email. Only when Medicine Buddha App has sent you an Order Confirmation
      will there be a legally binding contract between Medicine Buddha App and
      you (“the Contract”). 10.3.If you change your mind, you may cancel your
      Order or the Contract before or after Medicine Buddha App begin providing
      the Products and Services subject to these Terms and Conditions
      10.4.Medicine Buddha App may cancel your Order at any time before Medicine
      Buddha App begin providing the Products and Services in the following
      circumstances; 10.5.The required personnel and/or required materials
      necessary for the provision of the Products and Services are not
      available; or 10.6.An event outside of Our control (please refer to
      Clauses below for events outside of Our control). 10.7.If Medicine Buddha
      App cancel your Order and Medicine Buddha App has taken payment any such
      sums will be refunded to you as soon as possible and in any event within
      60 days. If Medicine Buddha App cancel your Order, you will be informed by
      email or p as appropriate. Provision of the Products and Services
      11.1.Medicine Buddha App will begin providing the Products and Services on
      the date agreed when you make your Order (which Medicine Buddha App shall
      confirm in the Order Confirmation). Please note that if you request that
      the Products and Services begin within the statutory 14 (fourteen)
      calendar day cancellation (or “cooling off”) period, your right to cancel
      may be limited or lost. Please refer to Clause 19, for your statutory
      cancellation rights. Medicine Buddha App will use all reasonable
      endeavours to provide the Products and Services with reasonable skill and
      care, commensurate with best practice. 11.2. Medicine Buddha App will make
      every reasonable effort to provide the Products and Services in a timely
      manner. Medicine Buddha App cannot be held responsible for any delays if
      an event outside of Our control occurs. 11.3. If Medicine Buddha App
      requires any information or action from you to provide the Products and
      Services, Medicine Buddha App will inform you of this as soon as is
      reasonably possible. 11.4. If the information you provide or the action
      you take under Clause 18, is delayed, incomplete or otherwise incorrect,
      Medicine Buddha App will not be responsible for any delay caused. 11.5.In
      certain circumstances, for example where Medicine Buddha App encounter a
      technical problem, Medicine Buddha App may need to suspend or otherwise
      interrupt the Products and Services to resolve the issue. Unless the issue
      is an emergency that requires immediate action Medicine Buddha App will
      inform you in advance by email before suspending or interrupting the
      Products and Services. 11.6.If the Products and Services are suspended or
      interrupted under Clauses 18, you will not be required to pay for them
      during the period of suspension if you access those Products and Services.
      You must, however, pay any sums that may already be due by the appropriate
      due date(s). 11.7.If you do not pay Medicine Buddha App for the Products
      and Services as required by Clause 17, Medicine Buddha App may suspend the
      Products and Services until you have paid all outstanding sums due. If
      this happens, we will inform you by email. An administrative fee of €50.00
      may be added. Your Legal Right to Cancel (Cooling Off Period) 12.1. If you
      are a consumer in the European Union, you have a legal right to a “cooling
      off” period within which you can cancel the Contract for any reason
      through our app/website. This period begins once your Order is accepted
      and Medicine Buddha App have sent you an Order Confirmation, i.e. when the
      Contract between you and Medicine Buddha App is formed. The period ends at
      the end of 14 calendar days’ after that date. 12.2. If the Products and
      Services are fully performed within the 14 (fourteen) calendar day cooling
      off period, you will lose your right to cancel after the Products and
      Services are complete. 12.3. If you cancel after provision of the Products
      and Services has begun but is not yet complete you will still be required
      to pay for the Products and Services provided up until the point at which
      you inform Medicine Buddha App that you wish to cancel. The amount due
      shall be calculated in proportion to the full price of the Products and
      Services and the actual Products and Services already provided including
      any initial administration costs. Any sums that have already been paid for
      the Products and Services shall be refunded subject to deductions
      calculated in accordance with the foregoing. Refunds, where applicable,
      will be issued within 60 calendar days’ after you inform Medicine Buddha
      App that you wish to cancel. Refunds will be made using the same payment
      method you used when ordering the Products and Services. Our Rights to
      Cancel 13.1. Medicine Buddha App may cancel the Products and Services
      after Medicine Buddha App have begun providing them due to an Event
      outside of Our control that continues for more than 30 (thirty) calendar
      days’, or due to the non-availability of required personnel and/or
      required materials necessary for the provision of the Products and
      Services. In such cases, you will only be required to pay for Products and
      Services that Medicine Buddha App have already provided up until the point
      at which Medicine Buddha App inform you that Medicine Buddha App are
      cancelling the contract. Such sums will be deducted from any refund due to
      you or, if no refund is due, Medicine Buddha App will invoice you for the
      relevant sums or no payment will be due from you and if you have already
      made any payment to us, such sums will be refunded to you. 13.2. Once
      Medicine Buddha App have begun providing the Products and Services,
      Medicine Buddha App may cancel the Contract at any time and will give you
      at least 30 calendar days’ written notice of such cancellation. You will
      only be required to pay for Products and Services that you have received.
      Such sums will be deducted from any refund due to you or, if no refund is
      due, Medicine Buddha App will invoice you for the relevant sums. 13.3
      Refunds due under this Clause 21 will be issued to you no later than 60
      (sixty) calendar days’ after the day on which Medicine Buddha App inform
      you of the cancellation. Refunds will be made using the same payment
      method you used when ordering the Products and Services unless you
      specifically request that Medicine Buddha App make a refund using a
      different method. Our Liability 14.1. Medicine Buddha App will be
      responsible for any foreseeable loss or damage that you may suffer because
      of Our breach of these Terms and Conditions or because of Our negligence.
      Loss or damage is foreseeable if it is an obvious consequence of Our
      breach or negligence or if it contemplated by you and Medicine Buddha App
      when the Contract is created. Medicine Buddha App will not be responsible
      for any loss or damage that is not foreseeable. 14.2. Medicine Buddha App
      provide Products and Services for domestic and private use or purposes.
      Medicine Buddha App make no warranty or representation that the Products
      and Services are fit for commercial, business or industrial purposes of
      any kind including resale. Medicine Buddha App will not be liable to you
      for any loss of profit, loss of business, interruption to business or for
      any loss of business opportunity. 14.3. Nothing in these Terms and
      Conditions seeks to exclude or limit Our liability for death or personal
      injury caused by Our negligence (including that of Our employees, agents
      or sub-contractors); or for fraud or fraudulent misrepresentation. Events
      Outside of Our Control (Force Majeure) 15.1.Medicine Buddha App will not
      be liable for any failure or delay in performing Our obligations where
      that failure or delay results from any event that is beyond Our reasonable
      control. Such causes include, but are not limited to: power failure,
      internet service provider failure, industrial action by third parties,
      civil unrest, fire, explosion, flood, storms, earthquakes, subsidence,
      acts of terrorism, acts of war, governmental action, epidemic or other
      natural disaster, or any other event that is beyond Our reasonable
      control. If the event outside of Our control continues for more than 30
      calendar days’ Medicine Buddha App may cancel the Contract and inform you
      of the cancellation. Any refunds due to you because of that cancellation
      will be paid to you as soon as is reasonably possible and, in any event,
      no later than 14 calendar days’ after the date on which Medicine Buddha
      App inform you of the cancellation; and If an event outside of Our control
      occurs and continues for more than 30 calendar days’ and you wish to
      cancel the Contract thus, you may do so. Any refunds due to you because of
      cancellation will be paid to you as soon as is reasonably possible and, in
      any event, no later than 14 calendar days’ after the date on which you
      inform Medicine Buddha App that you wish to cancel. Other Important Terms
      15.1.Cross-Border Data Transfers. We may process your data outside the
      European Union. Where we do we rely on legally-provided mechanisms to
      lawfully transfer data across borders and to process your data. 15.2.We do
      not store any credit or debit card information on Our servers. Payments
      are processed via a third-party payment provider that is fully compliant
      with Level 1 Payment Card Industry (PCI) data security standards. Any
      payment transactions are encrypted using SSL technology. 15.3.Medicine
      Buddha App Limited may transfer or assign Our obligations and rights under
      these Terms and Conditions (and under the Contract, as applicable) to a
      third party (this may happen, for example, if we sell Our business). If
      this occurs, Medicine Buddha App will inform you in writing. Your Medicine
      Buddha App rights under these Terms and Conditions will not be affected
      and Our obligations under these Terms and Conditions will be transferred
      to the third party who will remain bound by them. 15.4.You may not
      transfer (assign) your obligations and rights under these Terms and
      Conditions (and under the Contract, as applicable) without Our express
      written permission. 15.5 If any of the provisions of these Terms and
      Conditions are found to be unlawful, invalid or otherwise unenforceable by
      any court or other authority, those provision(s) shall be deemed severed
      from the remainder of these Terms and Conditions. The rest of these Terms
      and Conditions shall be valid and enforceable. Communications from
      Medicine Buddha App 16.1.If Medicine Buddha App has your contact details,
      Medicine Buddha App may from time to time send you important notices by
      email. Such notices may relate to matters including, but not limited to,
      service changes and changes to these Policies. 16.2.Medicine Buddha App
      will never send you marketing emails of any kind without your consent. If
      you do give such consent, you may opt out at any time. All marketing
      emails sent by Medicine Buddha App include an unsubscribe link. If you opt
      out of receiving emails from Medicine Buddha App at any time, it may take
      up to 7 business days for your new preferences to take effect. Law and
      Jurisdiction 17.1.These Terms and Conditions, and the relationship between
      you and Medicine Buddha App (whether contractual or otherwise) and
      Medicine Buddha App Limited shall be governed by and construed in
      accordance within the United Kingdom. Changes 18.1.Medicine Buddha App
      will attempt to keep the information and the resources contained on or
      accessible through its Apps and Websites timely and accurate, but makes no
      guarantees, and disclaims any implied warranty or representation, about
      its accuracy, relevance, timeliness, completeness, or appropriateness for
      a purpose. 18.2.Medicine Buddha App may change or modify the information,
      services and any other resources contained on or accessible through its
      Apps and Websites, or discontinue its Apps and Websites altogether, at any
      time without notice. 18.3.Medicine Buddha App reserves the right to change
      this Policy as we may deem necessary from time to time or as may be
      required by law. Any changes will be immediately posted on the App and
      Website and you will be notified on the website that the policy has been
      altered. You will be required to confirm that you accept the changes to
      the Policy prior to using certain services. Complaints and Feedback
      19.1.Medicine Buddha App always welcome feedback from Our Users and,
      whilst Medicine Buddha App always use all reasonable endeavor to ensure
      that your experience is a positive one. If you have any cause for
      complaint, please contact us through our website.
    </p>
  </>
);

export default Agreement;
