import * as _support from "../constants/support";
import { BACKEND_API } from "../constants/index";

export function raiseSupportTicket(reporter, details) {
  return async (dispatch) => {
    dispatch({
      type: _support.RAISING_TICKET,
    });
    const formdata = new FormData();
    formdata.append("reporter", reporter);
    formdata.append("details", details);
    const url = `${BACKEND_API}/raise/support/`;
    fetch(url, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: _support.TICKET_RAISED,
            data: res.data,
          });
        } else {
          dispatch({
            type: _support.TICKET_FAILED,
            data: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _support.TICKET_ERRORED,
        });
      });
  };
}

export function resetSupportTicket() {
  return async (dispatch) => {
    dispatch({ type: _support.TICKET_RESET });
  };
}

export function makeDonation(
  donationType,
  tokenId,
  currency,
  amount,
  donator,
  paymentDetails
) {
  return (dispatch) => {
    dispatch({
      type: _support.DONATION_REQUEST,
    });
    const formdata = new FormData();
    formdata.append("forward_type", donationType);
    formdata.append("token_id", tokenId);
    formdata.append("currency", currency);
    formdata.append("amount", amount);
    formdata.append("patient_id", donator);
    formdata.append("payment", JSON.stringify(paymentDetails));

    fetch(`${BACKEND_API}/payforward/confirm`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: _support.DONATION_SUCCESS,
            data: res.data,
          });
        } else {
          dispatch({
            type: _support.DONATION_FAILED,
            data: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _support.DONATION_FAILED,
          data: err.hasOwnProperty("message") ? err.message() : err,
        });
      });
  };
}
