import React from "react";

import { Icon, Modal, Button } from "semantic-ui-react";

const Notifier = ({ size, title, type, onClose, onOK, content }) => {
  const icon =
    type === "warn" ? (
      <Icon name="warning sign" color="orange" />
    ) : type === "info" ? (
      <Icon name="info circle" color="blue" />
    ) : null;
  return (
    <Modal
      size={size}
      open={true}
      centered={true}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {icon} {title}
      </Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      {(onClose || onOK) && (
        <Modal.Actions>
          {onClose && <Button onClick={onClose}>Close</Button>}
          {onOK && <Button onClick={onOK}>OK</Button>}
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default Notifier;
