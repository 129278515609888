import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Message,
  Dimmer,
  Segment,
  Modal,
  Loader,
  Button,
  Card,
} from "semantic-ui-react";

import * as _consultationAppointment from "../actions/consultation";
import DoctorCard from "../common/DoctorCard";
import DoctorProfile from "../common/DoctorProfile";
import Payment from "./Payment";
import { Redirect } from "react-router-dom";

class WellnessPractitioner extends React.Component {
  fetchDoctorInterval = false;
  requestStatusUpdateInterval = false;
  constructor(props) {
    super(props);
    this.state = {
      disclaimers: false,
      intiatePayment: false,
    };
  }

  getDoctors = () => {
    this.props.actions.getAvailableDoctors("THERAPIST");
  };

  componentDidMount() {
    if (!this.fetchDoctorInterval) {
      this.fetchDoctorInterval = setInterval(() => {
        this.getDoctors();
      }, 2500);
    }
    this.props.actions.getReasons("THERAPIST");
  }

  componentWillUnmount() {
    if (this.fetchDoctorInterval) {
      clearInterval(this.fetchDoctorInterval);
      this.fetchDoctorInterval = false;
    }
  }

  resetProfile = () => {
    this.props.actions.resetDoctorDetail();
  };

  paymentInitiation = () => {
    this.setState({
      intiatePayment: true,
    });
  };

  cancelPayment = () => {
    this.setState({ intiatePayment: false });
  };

  render() {
    const { disclaimers, intiatePayment } = this.state;
    const {
      user,
      currency,
      fetching,
      doctor,
      doctors,
      actions,
      requestingConsultation,
      consultationId,
      rtc_session_id,
      doctorFeedbacks,
    } = this.props;

    if (
      consultationId &&
      !rtc_session_id &&
      !this.requestStatusUpdateInterval
    ) {
      this.requestStatusUpdateInterval = setInterval(function () {
        actions.getConsultationStatusPatient(consultationId, user.id);
      }, 1500);
    }

    if (consultationId && rtc_session_id && this.requestStatusUpdateInterval) {
      clearInterval(this.requestStatusUpdateInterval);
      this.requestStatusUpdateInterval = false;
      return <Redirect to="/consultation" />;
    }

    const doctorCards = doctors.map((doc) => (
      <DoctorCard
        key={doc.id}
        id={doc.id}
        gravatar={doc.gravatar}
        available={doc.available}
        first_name={doc.first_name}
        last_name={doc.last_name}
        currency={currency}
        charges={doc.charges}
        getDetail={() => actions.getDoctorDetail(doc.id)}
      />
    ));

    const disclaimerModal = (
      <Modal
        open={!disclaimers}
        onClose={() => this.setState({ disclaimers: true })}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Disclaimer</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <p>
              All our wellness practitioners are carefully vetted by our team at
              Medicine Buddha. Our breathwork/meditation coaches and yoga
              instructors are insured and licensed. Our mind training monks have
              Geshe qualifications (PhD equivalent in Buddhist thought). Please
              see their individual profiles for more details.
            </p>
            <p>
              Consultations are not intended to serve as an alternative to
              necessary medical or other professional care, and no aspect of
              your consultation should be used as a reason to avoid any needed
              diagnostic workup or to alter or discontinue any medical
              treatment. Always check with your own doctor if you have any
              concerns about your condition or treatment.
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              actions.getAvailableDoctors("THERAPIST");
              this.setState({ disclaimers: true });
            }}
            primary
          >
            Confirm that you understand
          </Button>
        </Modal.Actions>
      </Modal>
    );
    return (
      <>
        {disclaimerModal}
        <DoctorProfile
          title="Wellness Practitioner"
          data={doctor}
          open={doctor != null && !intiatePayment}
          currency={currency}
          closeProfile={this.resetProfile}
          book={this.paymentInitiation}
          reviews={doctorFeedbacks}
          getReviews={() => {
            this.props.actions.getDoctorFeedback(doctor.id);
          }}
          userId={user.id}
        />
        {intiatePayment && (
          <Payment
            onCancel={this.cancelPayment}
            requestingConsultation={requestingConsultation}
          />
        )}
        <Segment raised>
          {fetching && (
            <Dimmer active>
              <Loader>Loading</Loader>
            </Dimmer>
          )}
          {doctors.length !== 0 && <Card.Group>{doctorCards}</Card.Group>}
          {doctors.length === 0 && !fetching && (
            <Message negative>
              <Message.Header>
                No Wellness Practitioner's profile found.
              </Message.Header>
              <p>We have logged this. And are looking into this.</p>
            </Message>
          )}
        </Segment>
      </>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.authSession,
      currency: state.user.authSession.currency,
      fetching: state.consultation.fetching,
      doctors: state.consultation.availableDoctors,
      doctor: state.consultation.selectedDoctor,
      requestingConsultation: state.consultation.requestingConsultation,
      consultationId: state.consultation.consultationId,
      rtc_session_id: state.consultation.rtc_session_id,
      doctorFeedbacks: state.consultation.doctorFeedbacks,
      // consultationMessage: state.consultation.consultationMessage,
      // stage: state.consultation.stage,
      // inConsultation: state.consultation.inConsultation,
      // prevConsultationId: state.consultation.prevConsultationId,
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(_consultationAppointment, dispatch),
  })
)(WellnessPractitioner);
